<template>
  <div
    class="page-default"
    :class="{ 'padding-subnav': this.$route.name == 'Analytics' }"
  >
    <div class="container-fluid h-100 px-3 py-2">
      <div
        class="row p-2 overflow-auto analytics-container"
        style="height: 100%"
      >
        <div class="col-12 col-md-12 col-lg-4 p-2 analytics-card">
          <div class="card h-100 w-100 p-2 cursor-default">
            <div class="filter-chart-contianer">
              <div
                class="
                  chart-header-container
                  d-flex
                  justify-content-between
                  align-items-center
                  py-1
                "
              >
                <p
                  class="m-0 font-smaller font-weight-bold ml-2"
                  style="color: #fff"
                >
                  Violation vs Intervention
                </p>
                <button
                  class="btn btn-sm btn-outline-info border-0 font-smaller"
                  @click="dataToExcel('bar')"
                >
                  <font-awesome-icon
                    :icon="['fas', 'file-download']"
                    class="h-100 mr-1"
                  />
                  Export to excel
                </button>
              </div>
              <div class="w-100 chart-filter-body">
                <div class="row m-0">
                  <div class="col-12 col-lg-6 p-0 pt-2 px-1">
                    <input
                      type="text"
                      class="
                        form-control
                        default
                        form-control-sm
                        select-small
                        pl-2
                      "
                      style="color: #fff; background-color: #282828"
                      v-model.lazy="modelTotal.area"
                      placeholder="Area"
                    />
                  </div>
                  <!-- <div class="col-12 col-lg-6 p-0">
                    <div class="btn-group  btn-group-sm w-100 mt-2 px-1" role="group" >
                      <button type="button" class="btn btn-pet" :class="{ active: modelTotal.hotwork == null }" @click="modelTotal.hotwork = null"> All </button>
                      <button type="button" class="btn btn-pet" :class="{ active: modelTotal.hotwork == 'false' }" @click="modelTotal.hotwork = 'false'"> Cold Work </button>
                      <button type="button" class="btn btn-pet" :class="{ active: modelTotal.hotwork == 'true' }" @click="modelTotal.hotwork = 'true'"> Hot Work </button>
                    </div>
                  </div> -->
                  <div class="col-12 col-lg-6 p-0 pt-2 px-1">
                    <select
                      class="
                        form-control form-control-sm
                        select-small
                        custom-select custom-select-outline custom-select-sm
                      "
                      v-model="modelTotal.phase"
                    >
                      <option :value="null">All Phase</option>
                      <option
                        v-for="option in phaseOptions"
                        :key="option.value"
                        :value="option.value"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="row m-0">
                  <div class="col-12 col-lg-6 p-0 pt-2 px-1">
                    <select
                      class="
                        form-control form-control-sm
                        select-small
                        custom-select custom-select-outline custom-select-sm
                      "
                      v-model="modelTotal.parameter"
                    >
                      <option :value="null">All Parameter</option>
                      <option
                        v-for="option in parametersOptions"
                        :key="option"
                        :value="option"
                      >
                        {{ option }}
                      </option>
                    </select>
                  </div>
                  <div class="col-12 col-lg-6 p-0 pt-2 px-1">
                    <select
                      class="
                        form-control form-control-sm
                        select-small
                        custom-select custom-select-outline custom-select-sm
                      "
                      v-model="modelTotal.contractor"
                      v-if="contractors"
                    >
                      <option :value="null">All Contractor</option>
                      <option
                        v-for="option in contractors"
                        :key="option.id"
                        :value="option.id"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <!-- <div class="btn-group  btn-group-sm w-100 mt-2 px-1" role="group" >
                  <button type="button" class="btn btn-pet" :class="{ active: modelTotal.location_type == null }" @click="modelTotal.location_type = null">All</button>
                  <button type="button" class="btn btn-pet" :class="{ active: modelTotal.location_type == 'Greenfield' }" @click="modelTotal.location_type = 'Greenfield'">Greenfield</button>
                  <button type="button" class="btn btn-pet" :class="{ active: modelTotal.location_type == 'Brownfield' }" @click="modelTotal.location_type = 'Brownfield'">Brownfield</button>
                </div> -->

                <div class="row m-0" v-if="!this.$route.params.slug">
                  <div class="col-12 col-lg-6 p-0 pt-2 px-1">
                    <select
                      class="form-control form-control-sm select-small
                        custom-select custom-select-outline custom-select-sm"
                      v-model="modelTotal.client"
                    >
                      <option :value="null">All OPU</option>
                      <option
                        v-for="option in clients"
                        :key="option.id"
                        :value="option.id"
                      >
                        {{ option.abbreviation }}
                      </option>
                    </select>
                  </div>
                  <div class="col-12 col-lg-6 p-0 pt-2 px-1">
                    <select
                      class="form-control form-control-sm select-small
                        custom-select custom-select-outline custom-select-sm"
                      v-model="modelTotal.type"
                    >
                      <option :value="null">All Type</option>
                      <option
                        v-for="option in typeOptions"
                        :key="option.value"
                        :value="option.value"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  class="btn-group btn-group-sm w-100 mt-2 px-1"
                  role="group"
                >
                  <button
                    type="button"
                    class="btn btn-pet"
                    :class="{ active: modelTotal.period == null }"
                    @click="modelTotal.period = null"
                  >
                    This Year
                  </button>
                  <button
                    type="button"
                    class="btn btn-pet"
                    :class="{ active: modelTotal.period == 'isoWeek' }"
                    @click="modelTotal.period = 'isoWeek'"
                  >
                    This Week
                  </button>
                  <button
                    type="button"
                    class="btn btn-pet"
                    :class="{ active: modelTotal.period == 'month' }"
                    @click="modelTotal.period = 'month'"
                  >
                    This Month
                  </button>
                  <button
                    type="button"
                    class="btn btn-pet"
                    :class="{ active: modelTotal.period == 'custom' }"
                    @click="modelTotal.period = 'custom'"
                  >
                    Custom
                  </button>
                </div>
                <div class="w-100 py-2 px-1">
                  <div class="dropdown h-100">
                    <button
                      id="dropdownMenuButton"
                      type="button"
                      class="
                        h-100
                        btn btn-sm
                        custom-select
                        w-100
                        dropdown-toggle
                        d-flex
                        justify-content-start
                        align-items-center
                        custom-select custom-select-outline custom-select-sm
                        no-pseudo
                        overflow-hidden
                      "
                      :disabled="modelTotal.period != 'custom'"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <font-awesome-icon
                        :icon="['far', 'calendar-alt']"
                        class="mr-2"
                      />
                      <small
                        v-if="modelTotal.date.start && modelTotal.date.end"
                        class="d-inline-block text-truncate"
                      >
                        {{
                          `${setDate(
                            modelTotal.date.start,
                            "date"
                          )} - ${setDate(modelTotal.date.end, "date")}`
                        }}
                      </small>
                      <small class="d-inline-block text-truncate" v-else>
                        No date selected
                      </small>
                    </button>
                    <div
                      id="dropdown-menu"
                      class="dropdown-menu px-2"
                      @click.stop
                    >
                      <div
                        class="
                          dropdown-item-text
                          d-flex
                          justify-content-between
                          align-items-center
                          px-0
                          pb-2
                        "
                      >
                        <p
                          class="
                            badge badge-primary
                            mb-0
                            font-weight-normal
                            p-2
                          "
                        >
                          Filter by date
                        </p>
                        <button
                          class="btn btn-sm btn-link p-0"
                          @click="modelTotal.date = { start: null, end: null }"
                        >
                          Clear
                        </button>
                      </div>
                      <v-date-picker
                        class="inline-calendar"
                        v-model="modelTotal.date"
                        :model-config="{
                          type: 'string',
                          mask: 'YYYY-MM-DD',
                        }"
                        mode="date"
                        trim-weeks
                        is-range
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pie-chart-contianer">
              <bar-chart
                class="w-100 h-100 chart-body d-flex justify-content-center"
                :chart-data="overallChart.data"
                :options="overallChart.options"
                v-if="overallChart.data"
              ></bar-chart>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-4 p-2 analytics-card">
          <div class="card h-100 w-100 p-2 cursor-default">
            <div class="filter-chart-contianer">
              <div
                class="
                  chart-header-container
                  d-flex
                  justify-content-between
                  align-items-center
                  py-1
                "
              >
                <p class="m-0 ml-2 font-smaller font-weight-bold" 
                  style="color: #fff">
                  Violation Trend
                </p>

                <button
                  class="btn btn-sm btn-outline-info border-0 font-smaller"
                  @click="dataToExcel('line')"
                >
                  <font-awesome-icon
                    :icon="['fas', 'file-download']"
                    class="h-100 mr-1"
                  />
                  Export to excel
                </button>
              </div>
              <div class="w-100 chart-filter-body">
                <div class="row m-0">
                  <div class="col-12 col-lg-6 p-0 pt-2 px-1">
                    <input
                      type="text"
                      class="
                        form-control
                        default
                        form-control-sm
                        pl-2
                        select-small
                      "
                      style="color: #fff; background-color: #282828"
                      v-model.lazy="modelTrend.area"
                      placeholder="Area"
                    />
                  </div>
                  <div class="col-12 col-lg-6 p-0 pt-2 px-1">
                    <select
                      class="form-control form-control-sm select-small custom-select custom-select-outline custom-select-sm"
                      v-model="modelTrend.phase"
                    >
                      <option :value="null">All Phase</option>
                      <option
                        v-for="option in phaseOptions"
                        :key="option.value"
                        :value="option.value"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                  <!-- <div class="col-12 col-lg-6 p-0">
                    <div class="btn-group  btn-group-sm w-100 mt-2 px-1" role="group" >
                      <button type="button" class="btn btn-pet" :class="{ active: modelTrend.hotwork == null }" @click="modelTrend.hotwork = null"> All </button>
                      <button type="button" class="btn btn-pet" :class="{ active: modelTrend.hotwork == 'false' }" @click="modelTrend.hotwork = 'false'"> Cold Work </button>
                      <button type="button" class="btn btn-pet" :class="{ active: modelTrend.hotwork == 'true' }" @click="modelTrend.hotwork = 'true'"> Hot Work </button>
                    </div>
                  </div> -->
                </div>
                <div class="row m-0">
                  <div class="col-12 col-lg-6 p-0 pt-2 px-1">
                    <select
                      class="form-control form-control-sm select-small custom-select custom-select-outline custom-select-sm"
                      v-model="modelTrend.parameter"
                    >
                      <option :value="null">All Parameter</option>
                      <option
                        v-for="option in parametersOptions"
                        :key="option"
                        :value="option"
                      >
                        {{ option }}
                      </option>
                    </select>
                  </div>
                  <div class="col-12 col-lg-6 p-0 pt-2 px-1">
                    <select
                      class="form-control form-control-sm select-small custom-select custom-select-outline custom-select-sm"
                      v-model="modelTrend.contractor"
                      v-if="contractors"
                    >
                      <option :value="null">All Contractor</option>
                      <option
                        v-for="option in contractors"
                        :key="option.id"
                        :value="option.id"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="row m-0" v-if="!this.$route.params.slug">
                  <div class="col-12 col-lg-6 p-0 pt-2 px-1">
                    <select
                      class="form-control form-control-sm select-small custom-select custom-select-outline custom-select-sm"
                      v-model="modelTrend.client"
                    >
                      <option :value="null">All OPU</option>
                      <option
                        v-for="option in clients"
                        :key="option.id"
                        :value="option.id"
                      >
                        {{ option.abbreviation }}
                      </option>
                    </select>
                  </div>
                  <div class="col-12 col-lg-6 p-0 pt-2 px-1">
                    <select
                      class="form-control form-control-sm select-small custom-select custom-select-outline custom-select-sm"
                      v-model="modelTrend.projectType"
                    >
                      <option :value="null">All Type</option>
                      <option
                        v-for="option in typeOptions"
                        :key="option.value"
                        :value="option.value"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <!-- <div class="btn-group  btn-group-sm w-100 mt-2 px-1" role="group" aria-label="Basic example">
                  <button type="button" class="btn btn-pet" :class="{ active: modelTrend.location_type ==  null}" @click="modelTrend.location_type = null">All</button>
                  <button type="button" class="btn btn-pet" :class="{ active: modelTrend.location_type == 'Greenfield' }" @click="modelTrend.location_type = 'Greenfield'"> Greenfield </button>
                  <button type="button" class="btn btn-pet" :class="{ active: modelTrend.location_type == 'Brownfield' }" @click="modelTrend.location_type = 'Brownfield'">Brownfield</button>
                </div> -->
                <div
                  class="btn-group btn-group-sm w-100 mt-2 px-1"
                  role="group"
                >
                  <button
                    type="button"
                    class="btn btn-pet"
                    :class="{ active: modelTrend.type == 'year' }"
                    @click="modelTrend.type = 'year'"
                  >
                    This Year
                  </button>
                  <button
                    type="button"
                    class="btn btn-pet"
                    :class="{ active: modelTrend.type == 'isoWeek' }"
                    @click="modelTrend.type = 'isoWeek'"
                  >
                    This Week
                  </button>
                  <button
                    type="button"
                    class="btn btn-pet"
                    :class="{ active: modelTrend.type == 'month' }"
                    @click="modelTrend.type = 'month'"
                  >
                    This Month
                  </button>
                  <button
                    type="button"
                    class="btn btn-pet"
                    :class="{ active: modelTrend.type == 'custom' }"
                    @click="modelTrend.type = 'custom'"
                  >
                    Custom
                  </button>
                </div>
                <div class="w-100 py-2 px-1">
                  <div class="dropdown h-100">
                    <button
                      id="dropdownMenuButton"
                      type="button"
                      class="
                        h-100
                        btn btn-sm
                        custom-select
                        w-100
                        dropdown-toggle
                        d-flex
                        custom-select custom-select-outline custom-select-sm
                        justify-content-start
                        align-items-center
                        no-pseudo
                        overflow-hidden
                      "
                      :disabled="modelTrend.type != 'custom'"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <font-awesome-icon
                        :icon="['far', 'calendar-alt']"
                        class="mr-2"
                      />
                      <small
                        v-if="modelTrend.date.start && modelTrend.date.end"
                        class="d-inline-block text-truncate"
                      >
                        {{
                          `${setDate(
                            modelTrend.date.start,
                            "date"
                          )} - ${setDate(modelTrend.date.end, "date")}`
                        }}
                      </small>
                      <small class="d-inline-block text-truncate" v-else>
                        No date selected
                      </small>
                    </button>
                    <div
                      id="dropdown-menu-trend"
                      class="dropdown-menu px-2"
                      @click.stop
                    >
                      <div
                        class="
                          dropdown-item-text
                          d-flex
                          justify-content-between
                          align-items-center
                          px-0
                          pb-2
                        "
                      >
                        <p
                          class="
                            badge badge-primary
                            mb-0
                            font-weight-normal
                            p-2
                          "
                        >
                          Filter by date
                        </p>
                        <button
                          class="btn btn-sm btn-link p-0"
                          @click="modelTrend.date = { start: null, end: null }"
                        >
                          Clear
                        </button>
                      </div>
                      <v-date-picker
                        class="inline-calendar"
                        v-model="modelTrend.date"
                        :model-config="{
                          type: 'string',
                          mask: 'YYYY-MM-DD',
                        }"
                        mode="date"
                        trim-weeks
                        is-range
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pie-chart-contianer">
              <line-chart
                class="w-100 chart-body"
                :chart-data="trendChart.data"
                :options="trendChart.options"
                v-if="trendChart.data"
              ></line-chart>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-4 p-2 analytics-card">
          <div class="card h-100 w-100 p-2 cursor-default">
            <div class="filter-chart-contianer">
              <div
                class="
                  chart-header-container
                  d-flex
                  justify-content-between
                  align-items-center
                  py-1
                  pl-2
                "
              >
                <p class="m-0 font-smaller font-weight-bold"
                  style="color: #fff">
                  Violation Breakdown
                </p>

                <button
                  class="btn btn-sm btn-outline-info border-0 font-smaller"
                  @click="dataToExcel('pie')"
                >
                  <font-awesome-icon
                    :icon="['fas', 'file-download']"
                    class="h-100 mr-1"
                  />
                  Export to excel
                </button>
              </div>
              <div class="w-100 chart-filter-body">
                <div class="row m-0">
                  <div class="col-12 col-lg-6 p-0 pt-2 px-1">
                    <input
                      type="text"
                      class="
                        form-control
                        default
                        form-control-sm
                        select-small
                        pl-2
                      "
                      style="color: #fff; background-color: #282828"
                      v-model.lazy="modelPie.area"
                      placeholder="Area"
                    />
                  </div>

                  <div class="col-12 col-lg-6 p-0 pt-2 px-1">
                    <select
                      class="form-control form-control-sm select-small custom-select custom-select-outline custom-select-sm"
                      v-model="modelPie.phase"
                    >
                      <option :value="null">All Phase</option>
                      <option
                        v-for="option in phaseOptions"
                        :key="option.value"
                        :value="option.value"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                  <!-- <div class="col-12 col-lg-6 p-0">
                    <div class="btn-group  btn-group-sm w-100 mt-2 px-1" role="group" >
                      <button type="button" class="btn btn-pet" :class="{ active: modelPie.hotwork == null }" @click="modelPie.hotwork = null"> All </button>
                      <button type="button" class="btn btn-pet" :class="{ active: modelPie.hotwork == 'false' }" @click="modelPie.hotwork = 'false'"> Cold Work </button>
                      <button type="button" class="btn btn-pet" :class="{ active: modelPie.hotwork == 'true' }" @click="modelPie.hotwork = 'true'"> Hot Work </button>
                    </div>
                  </div> -->
                </div>

                <div class="pt-2 px-1">
                  <select
                    class="form-control form-control-sm select-small custom-select custom-select-outline custom-select-sm"
                    v-model="modelPie.contractor"
                    v-if="contractors"
                  >
                    <option :value="null">All Contractor</option>
                    <option
                      v-for="option in contractors"
                      :key="option.id"
                      :value="option.id"
                    >
                      {{ option.name }}
                    </option>
                  </select>
                </div>
                <div class="row m-0" v-if="!this.$route.params.slug">
                  <div class="col-12 col-lg-6 p-0 pt-2 px-1">
                    <select
                      class="form-control form-control-sm select-small custom-select custom-select-outline custom-select-sm"
                      v-model="modelPie.client"
                    >
                      <option :value="null">All OPU</option>
                      <option
                        v-for="option in clients"
                        :key="option.id"
                        :value="option.id"
                      >
                        {{ option.abbreviation }}
                      </option>
                    </select>
                  </div>
                  <div class="col-12 col-lg-6 p-0 pt-2 px-1">
                    <select
                      class="form-control form-control-sm select-small custom-select custom-select-outline custom-select-sm"
                      v-model="modelPie.type"
                    >
                      <option :value="null">All Type</option>
                      <option
                        v-for="option in typeOptions"
                        :key="option.value"
                        :value="option.value"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <!-- <div class="btn-group  btn-group-sm w-100 mt-2 px-1" role="group" aria-label="Basic example">
                  <button type="button" class="btn btn-pet" :class="{ active: modelPie.period == null }" @click="modelPie.period = null">All</button>
                  <button type="button" class="btn btn-pet" :class="{ active: modelPie.period == 'week' }" @click="modelPie.period = 'week'">
                    This Week
                  </button>
                  <button type="button" class="btn btn-pet" :class="{ active: modelPie.period == 'month' }" @click="modelPie.period = 'month'">This Month</button>
                </div> -->
                <!-- <div class="btn-group  btn-group-sm w-100 mt-2 px-1" role="group" aria-label="Basic example">
                  <button type="button" class="btn btn-pet" :class="{ active: modelPie.location_type == null }" @click="modelPie.location_type = null">All</button>
                  <button type="button" class="btn btn-pet" :class="{ active: modelPie.location_type == 'Greenfield' }" @click="modelPie.location_type = 'Greenfield'"> Greenfield </button>
                  <button type="button" class="btn btn-pet" :class="{ active: modelPie.location_type == 'Brownfield' }" @click="modelPie.location_type = 'Brownfield'">Brownfield</button>
                </div> -->

                <div
                  class="btn-group btn-group-sm w-100 mt-2 px-1"
                  role="group"
                >
                  <button
                    type="button"
                    class="btn btn-pet"
                    :class="{ active: modelPie.period == null }"
                    @click="modelPie.period = null"
                  >
                    This Year
                  </button>
                  <button
                    type="button"
                    class="btn btn-pet"
                    :class="{ active: modelPie.period == 'isoWeek' }"
                    @click="modelPie.period = 'isoWeek'"
                  >
                    This Week
                  </button>
                  <button
                    type="button"
                    class="btn btn-pet"
                    :class="{ active: modelPie.period == 'month' }"
                    @click="modelPie.period = 'month'"
                  >
                    This Month
                  </button>
                  <button
                    type="button"
                    class="btn btn-pet"
                    :class="{ active: modelPie.period == 'custom' }"
                    @click="modelPie.period = 'custom'"
                  >
                    Custom
                  </button>
                </div>
                <div class="w-100 py-2 px-1">
                  <div class="dropdown h-100">
                    <button
                      id="dropdownMenuButton"
                      type="button"
                      class="
                        h-100
                        btn btn-sm
                        custom-select
                        w-100
                        dropdown-toggle
                        d-flex
                        justify-content-start 
                        custom-select custom-select-outline custom-select-sm
                        align-items-center
                        no-pseudo
                        overflow-hidden
                      "
                      :disabled="modelPie.period != 'custom'"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <font-awesome-icon
                        :icon="['far', 'calendar-alt']"
                        class="mr-2"
                      />
                      <small
                        v-if="modelPie.date.start && modelPie.date.end"
                        class="d-inline-block text-truncate"
                      >
                        {{
                          `${setDate(modelPie.date.start, "date")} - ${setDate(
                            modelPie.date.end,
                            "date"
                          )}`
                        }}
                      </small>
                      <small class="d-inline-block text-truncate" v-else>
                        No date selected
                      </small>
                    </button>
                    <div
                      id="dropdown-menu-pie"
                      class="dropdown-menu px-2"
                      @click.stop
                    >
                      <div
                        class="
                          dropdown-item-text
                          d-flex
                          justify-content-between
                          align-items-center
                          px-0
                          pb-2
                        "
                      >
                        <p
                          class="
                            badge badge-primary
                            mb-0
                            font-weight-normal
                            p-2
                          "
                        >
                          Filter by date
                        </p>
                        <button
                          class="btn btn-sm btn-link p-0"
                          @click="modelPie.date = { start: null, end: null }"
                        >
                          Clear
                        </button>
                      </div>
                      <v-date-picker
                        class="inline-calendar"
                        v-model="modelPie.date"
                        :model-config="{
                          type: 'string',
                          mask: 'YYYY-MM-DD',
                        }"
                        mode="date"
                        trim-weeks
                        is-range
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pie-chart-contianer">
              <div class="w-100 h-100 chart-body d-flex justify-content-center">
                <pie-chart
                  class="w-100 h-100 d-flex justify-content-center"
                  :chart-data="pieChart.data"
                  :options="pieChart.options"
                  v-if="pieChart.data"
                ></pie-chart>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-4 p-2 analytics-card">
          <div class="card h-100 w-100 p-2 cursor-default">
            <div class="filter-chart-contianer">
              <div
                class="
                  chart-header-container
                  d-flex
                  justify-content-between
                  align-items-center
                  py-1
                "
              >
                <p class="m-0 ml-2 font-smaller font-weight-bold"
                  style="color: #fff">
                  Cumulative hourly violation trend
                </p>

                <button
                  class="btn btn-sm btn-outline-info border-0 font-smaller"
                  @click="dataToExcel('line')"
                >
                  <font-awesome-icon
                    :icon="['fas', 'file-download']"
                    class="h-100 mr-1"
                  />
                  Export to excel
                </button>
              </div>
              <div class="w-100 chart-filter-body">
                <div class="row m-0">
                  <div class="col-12 col-lg-6 p-0 pt-2 px-1">
                    <input
                      type="text"
                      class="
                        form-control
                        default
                        form-control-sm
                        pl-2
                        select-small
                      "
                      style="color: #fff; background-color: #282828"
                      v-model.lazy="modelTrendHourly.area"
                      placeholder="Area"
                    />
                  </div>
                  <div class="col-12 col-lg-6 p-0 pt-2 px-1">
                    <select
                      class="form-control form-control-sm select-small custom-select custom-select-outline custom-select-sm"
                      v-model="modelTrendHourly.phase"
                    >
                      <option :value="null">All Phase</option>
                      <option
                        v-for="option in phaseOptions"
                        :key="option.value"
                        :value="option.value"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                  <!-- <div class="col-12 col-lg-6 p-0">
                    <div class="btn-group  btn-group-sm w-100 mt-2 px-1" role="group" >
                      <button type="button" class="btn btn-pet" :class="{ active: modelTrend.hotwork == null }" @click="modelTrend.hotwork = null"> All </button>
                      <button type="button" class="btn btn-pet" :class="{ active: modelTrend.hotwork == 'false' }" @click="modelTrend.hotwork = 'false'"> Cold Work </button>
                      <button type="button" class="btn btn-pet" :class="{ active: modelTrend.hotwork == 'true' }" @click="modelTrend.hotwork = 'true'"> Hot Work </button>
                    </div>
                  </div> -->
                </div>
                <div class="row m-0">
                  <div class="col-12 col-lg-6 p-0 pt-2 px-1">
                    <select
                      class="form-control form-control-sm select-small custom-select custom-select-outline custom-select-sm"
                      v-model="modelTrendHourly.parameter"
                    >
                      <option :value="null">All Parameter</option>
                      <option
                        v-for="option in parametersOptions"
                        :key="option"
                        :value="option"
                      >
                        {{ option }}
                      </option>
                    </select>
                  </div>
                  <div class="col-12 col-lg-6 p-0 pt-2 px-1">
                    <select
                      class="form-control form-control-sm select-small custom-select custom-select-outline custom-select-sm"
                      v-model="modelTrendHourly.contractor"
                      v-if="contractors"
                    >
                      <option :value="null">All Contractor</option>
                      <option
                        v-for="option in contractors"
                        :key="option.id"
                        :value="option.id"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="row m-0" v-if="!this.$route.params.slug">
                  <div class="col-12 col-lg-6 p-0 pt-2 px-1">
                    <select
                      class="form-control form-control-sm select-small custom-select custom-select-outline custom-select-sm"
                      v-model="modelTrendHourly.client"
                    >
                      <option :value="null">All OPU</option>
                      <option
                        v-for="option in clients"
                        :key="option.id"
                        :value="option.id"
                      >
                        {{ option.abbreviation }}
                      </option>
                    </select>
                  </div>
                  <div class="col-12 col-lg-6 p-0 pt-2 px-1">
                    <select
                      class="form-control form-control-sm select-small custom-select custom-select-outline custom-select-sm"
                      v-model="modelTrendHourly.projectType"
                    >
                      <option :value="null">All Type</option>
                      <option
                        v-for="option in typeOptions"
                        :key="option.value"
                        :value="option.value"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <!-- <div class="btn-group  btn-group-sm w-100 mt-2 px-1" role="group" aria-label="Basic example">
                  <button type="button" class="btn btn-pet" :class="{ active: modelTrend.location_type ==  null}" @click="modelTrend.location_type = null">All</button>
                  <button type="button" class="btn btn-pet" :class="{ active: modelTrend.location_type == 'Greenfield' }" @click="modelTrend.location_type = 'Greenfield'"> Greenfield </button>
                  <button type="button" class="btn btn-pet" :class="{ active: modelTrend.location_type == 'Brownfield' }" @click="modelTrend.location_type = 'Brownfield'">Brownfield</button>
                </div> -->
                <div
                  class="btn-group btn-group-sm w-100 mt-2 px-1"
                  role="group"
                >
                  <button
                    type="button"
                    class="btn btn-pet"
                    :class="{ active: modelTrendHourly.type == 'year' }"
                    @click="modelTrendHourly.type = 'year'"
                  >
                    This Year
                  </button>
                  <button
                    type="button"
                    class="btn btn-pet"
                    :class="{ active: modelTrendHourly.type == 'isoWeek' }"
                    @click="modelTrendHourly.type = 'isoWeek'"
                  >
                    This Week
                  </button>
                  <button
                    type="button"
                    class="btn btn-pet"
                    :class="{ active: modelTrendHourly.type == 'month' }"
                    @click="modelTrendHourly.type = 'month'"
                  >
                    This Month
                  </button>
                  <button
                    type="button"
                    class="btn btn-pet"
                    :class="{ active: modelTrendHourly.type == 'custom' }"
                    @click="modelTrendHourly.type = 'custom'"
                  >
                    Custom
                  </button>
                </div>
                <div class="w-100 py-2 px-1">
                  <div class="dropdown h-100">
                    <button
                      id="dropdownMenuButton"
                      type="button"
                      class="
                        h-100
                        btn btn-sm
                        custom-select
                        w-100
                        dropdown-toggle
                        d-flex
                        justify-content-start
                        custom-select custom-select-outline custom-select-sm
                        align-items-center
                        no-pseudo
                        overflow-hidden
                      "
                      :disabled="modelTrendHourly.type != 'custom'"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <font-awesome-icon
                        :icon="['far', 'calendar-alt']"
                        class="mr-2"
                      />
                      <small
                        v-if="
                          modelTrendHourly.date.start &&
                          modelTrendHourly.date.end
                        "
                        class="d-inline-block text-truncate"
                      >
                        {{
                          `${setDate(
                            modelTrendHourly.date.start,
                            "date"
                          )} - ${setDate(modelTrendHourly.date.end, "date")}`
                        }}
                      </small>
                      <small class="d-inline-block text-truncate" v-else>
                        No date selected
                      </small>
                    </button>
                    <div
                      id="dropdown-menu-trendhourly"
                      class="dropdown-menu px-2"
                      @click.stop
                    >
                      <div
                        class="
                          dropdown-item-text
                          d-flex
                          justify-content-between
                          align-items-center
                          px-0
                          pb-2
                        "
                      >
                        <p
                          class="
                            badge badge-primary
                            mb-0
                            font-weight-normal
                            p-2
                          "
                        >
                          Filter by date
                        </p>
                        <button
                          class="btn btn-sm btn-link p-0"
                          @click="
                            modelTrendHourly.date = { start: null, end: null }
                          "
                        >
                          Clear
                        </button>
                      </div>
                      <v-date-picker
                        class="inline-calendar"
                        v-model="modelTrendHourly.date"
                        :model-config="{
                          type: 'string',
                          mask: 'YYYY-MM-DD',
                        }"
                        mode="date"
                        trim-weeks
                        is-range
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pie-chart-contianer">
              <line-chart
                class="w-100 chart-body"
                :chart-data="trendHourlyChart.data"
                :options="trendHourlyChart.options"
                v-if="trendHourlyChart.data"
              ></line-chart>
            </div>
          </div>
        </div>
        <!-- <div class="col-12 col-md-12 col-lg-4 p-2 analytics-card">
          <div class="card h-100 w-100 p-3 cursor-default">
            <div
              class="
                chart-header-container
                d-flex
                justify-content-between
                align-items-center
                m-0
                py-1
              "
              style="height: 32px"
            >
              <p class="m-0">Top Performers</p>
            </div>
            <div
              class="w-100 chart-body"
              style="height: calc(100% - 1rem - 32px)"
            >
              <div class="w-100" v-for="(rank, index) in rankings" :key="index">
                <Ranking :rank="rank" :index="index" />
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div class="col-12 col-md-12 col-lg-4 p-2 analytics-card">
          <div class="card h-100 w-100 p-2 cursor-default">
            <div class="filter-chart-contianer">
              <div
                class="
                  chart-header-container
                  d-flex
                  justify-content-between
                  align-items-center
                  py-1
                  pl-2
                "
              >
                <p class="m-0 font-smaller font-weight-bold"></p>
              </div>
              <div class="w-100 chart-filter-body"></div>
            </div>
            <div class="pie-chart-contianer"></div>
          </div>
        </div> -->
      </div>
    </div>

    <interventions @hideIntervene="hideIntervene" v-if="showIntervene" />
  </div>
</template>

<script>
import StackBarChart from "@/components/StackBarChart";
import LineChart from "@/components/LineChart";
import PieChart from "@/components/PieChart";
// import axios from "axios";
import XLSX from "xlsx";
import moment from "moment";
import API from "@/api";
import DATE from "@/date.js";
import Interventions from "@/components/Interventions";
import $ from "jquery";
// import Ranking from "../components/Ranking.vue";

export default {
  components: {
    // Ranking,
    "bar-chart": StackBarChart,
    "line-chart": LineChart,
    "pie-chart": PieChart,
    interventions: Interventions
  },
  watch: {
    modelTotal: {
      handler() {
        if (this.modelTotal.period == "custom") {
          if (this.modelTotal.date.start && this.modelTotal.date.end) {
            $("#dropdown-menu").removeClass("show");
            this.getAnalyticsTotal();
          }
        } else {
          if (
            this.modelTotal.date.start != null ||
            this.modelTotal.date.end != null
          ) {
            this.modelTotal.date = {
              start: null,
              end: null
            };
          }
          this.getAnalyticsTotal();
        }
      },
      immediate: true,
      deep: true
      // console.log(this.modelTotal)
    },
    modelTrend: {
      handler() {
        if (this.modelTrend.type == "custom") {
          if (this.modelTrend.date.start && this.modelTrend.date.end) {
            $("#dropdown-menu-trend").removeClass("show");
            this.getAnalyticsTrend();
          }
        } else {
          if (
            this.modelTrend.date.start != null ||
            this.modelTrend.date.end != null
          ) {
            this.modelTrend.date = {
              start: null,
              end: null
            };
          }
          this.getAnalyticsTrend();
        }
      },
      immediate: true,
      deep: true
      // console.log(this.modelTotal)
    },
    modelTrendHourly: {
      handler() {
        if (this.modelTrendHourly.type == "custom") {
          if (
            this.modelTrendHourly.date.start &&
            this.modelTrendHourly.date.end
          ) {
            $("#dropdown-menu-trendhourly").removeClass("show");
            this.getAnalyticsTrendHourly();
          }
        } else {
          if (
            this.modelTrendHourly.date.start != null ||
            this.modelTrendHourly.date.end != null
          ) {
            this.modelTrendHourly.date = {
              start: null,
              end: null
            };
          }
          this.getAnalyticsTrendHourly();
        }
      },
      immediate: true,
      deep: true
      // console.log(this.modelTotal)
    },
    modelPie: {
      handler() {
        if (this.modelPie.period == "custom") {
          if (this.modelPie.date.start && this.modelPie.date.end) {
            $("#dropdown-menu-pie").removeClass("show");
            this.getAnalyticsPie();
          }
        } else {
          if (
            this.modelPie.date.start != null ||
            this.modelPie.date.end != null
          ) {
            this.modelPie.date = {
              start: null,
              end: null
            };
          }
          this.getAnalyticsPie();
        }
      },
      immediate: true,
      deep: true
      // console.log(this.modelTotal)
    }
  },
  data() {
    return {
      rankings: null,
      showIntervene: false,
      contractors: null,
      clients: null,
      phaseOptions: [
        {
          name: "Site Prep",
          value: "prep"
        },
        {
          name: "Execution",
          value: "execution"
        },
        {
          name: "Commissioning",
          value: "commissioning"
        },
        {
          name: "Provisional",
          value: "provisional"
        }
      ],
      typeOptions: [
        {
          name: "On Shore",
          value: "onshore"
        },
        {
          name: "Off Shore",
          value: "offshore"
        }
      ],
      parametersOptions: [
        "No Helmet",
        "No Mask",
        "No Welding Mask",
        "No Goggles",
        "No Coverall",
        "No Glove",
        "No Shoes",
        "Unauthorized Person",
        "No Gas Mask",
        "No Earmuff",
        "Phone",
        "Smoking",
        "No Standby Person",
        "No Fire Extinguisher",
        "Speed Limit"
      ],
      modelTotal: {
        date: {
          start: null,
          end: null
        },
        type: null,
        client: null,
        phase: null,
        period: null,
        parameter: null,
        location_type: null,
        contractor: null,
        hotwork: null,
        area: null
      },
      modelTrend: {
        date: {
          start: null,
          end: null
        },
        projectType: null,
        client: null,
        phase: null,
        type: "year",
        parameter: null,
        location_type: null,
        area: null,
        contractor: null,
        hotwork: null
      },
      modelTrendHourly: {
        date: {
          start: null,
          end: null
        },
        projectType: null,
        client: null,
        phase: null,
        type: "year",
        parameter: null,
        location_type: null,
        area: null,
        contractor: null,
        hotwork: null
      },
      modelPie: {
        date: {
          start: null,
          end: null
        },
        type: null,
        client: null,
        phase: null,
        period: null,
        location_type: null,
        area: null,
        contractor: null,
        hotwork: null
      },
      overallChart: {
        data: null,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                stacked: true,
                gridLines: {
                  display: false
                }
              }
            ],
            yAxes: [
              {
                stacked: true,
                gridLines: {
                  display: true,
                  color: 'rgba(255, 255, 255, 0.35)'
                }
              }
            ]
          }
        }
      },
      trendChart: {
        data: null,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          lineTension: 0,
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false
                }
              }
            ],
            yAxes:[
              {
                gridLines: {
                  display: true,
                  color: 'rgba(255, 255, 255, 0.35)'
                }
              }
            ]
          }
        }
      },
      trendHourlyChart: {
        data: null,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          lineTension: 0,
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false
                }
              }
            ],
            yAxes:[
              {
                gridLines: {
                  display: true,
                  color: 'rgba(255, 255, 255, 0.35)'
                }
              }
            ]
          }
        }
      },
      trendYearChart: {
        data: null,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          lineTension: 0,
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false
                }
              }
            ]
          }
        }
      },
      pieChart: {
        data: null,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true,
            // position: 'right',
            labels: {
              // This more specific font property overrides the global property
              fontSize: 10,
              boxWidth: 10
            }
          },
          borderWidth: "10px",
          hoverBackgroundColor: "red",
          hoverBorderWidth: "10px"
        }
      },
      recentInterventions: null
    };
  },
  computed: {
    myStyles() {
      return {
        position: "relative",
        height: "100%",
        width: "100%"
      };
    }
  },
  methods: {
    setDate(date, type) {
      return DATE[type || "full"](date);
    },
    hideIntervene() {
      this.showIntervene = false;
    },
    full() {
      return moment().format("DDMMYYYY_hhmm");
    },
    dataToExcel(type) {
      if (type == "line" || type == "line_year") {
        let chartData = type == "line" ? this.trendChart : this.trendYearChart;
        let data = chartData.data.datasets[0].data.map(d => d);
        let labels = chartData.data.labels;
        let dataPoints = labels.map((l, index) => {
          return { date: l, value: data[index] };
        });
        dataPoints.unshift({ date: "Date", value: "Total Violation" });
        var ws = XLSX.utils.json_to_sheet(dataPoints, {
          skipHeader: true,
          dateNF: "YYYYMMDD HH:mm:ss"
        });
        if (!ws["!cols"]) ws["!cols"] = [];
        ws["!cols"][0] = { wch: 17 };
        var wb = XLSX.utils.book_new();
        let filename =
          type == "line" ? "violation_trend" : "violation_trend_2021";
        XLSX.utils.book_append_sheet(wb, ws, filename);
        XLSX.writeFile(wb, filename + this.full() + ".xlsx");
      } else if (type == "pie") {
        let data = this.pieChart.data.datasets[0].data.map(d => d);
        let labels = this.pieChart.data.labels;
        let dataPoints = labels.map((l, index) => {
          return { date: l, value: data[index] };
        });
        dataPoints.unshift({ date: "Violation", value: "Total Violation" });
        console.log(dataPoints);

        var wsp = XLSX.utils.json_to_sheet(dataPoints, {
          skipHeader: true,
          dateNF: "YYYYMMDD HH:mm:ss"
        });

        var wbp = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wbp, wsp, "filename");
        XLSX.writeFile(wbp, "overall_breakdown_" + this.full() + ".xlsx");
      } else {
        let violations = this.overallChart.data.datasets[0].data.map(d => d);
        let intervenes = this.overallChart.data.datasets[1].data.map(d => d);
        let labels = this.overallChart.data.labels;
        let dataPoints = labels.map((l, index) => {
          return {
            camera: l,
            value: violations[index],
            value2: intervenes[index]
          };
        });
        dataPoints.unshift({
          camera: "Camera",
          value: "Total Violation",
          value2: "Total Intervene"
        });

        var wsb = XLSX.utils.json_to_sheet(dataPoints, {
          skipHeader: true,
          dateNF: "YYYYMMDD HH:mm:ss"
        });

        var wbb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wbb, wsb, "filename");
        XLSX.writeFile(wbb, "violation_intervene" + this.full() + ".xlsx");
      }
    },
    async getInterventions() {
      const res = await API.get(
        `interventionsByProject/${this.$route.params.slug}?_sort=created_at:DESC&_limit=5`
      );
      this.recentInterventions = res.data;
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
    async getAnalyticsTotal() {
      let params = this.$route.params.slug
        ? { project: this.$route.params.slug }
        : {};
      for (let [key, value] of Object.entries(this.modelTotal)) {
        if (value != null && value != "" && typeof value !== "object") {
          params[key] = value;
        } else if (value != null && typeof value == "object") {
          for (let [key2, value2] of Object.entries(value)) {
            params[key2] = value2;
          }
        }
      }
      console.log(JSON.stringify(params));
      let url = "analytics/total?";
      var queryString = Object.keys(params)
        .map(key => key + "=" + params[key])
        .join("&");
      // console.log(JSON.stringify(queryString));
      console.log(`${url}${queryString}`);

      const res = await API.get(`${url}${queryString}`);

      console.log(res);

      const data = [
        {
          label: "Violation",
          backgroundColor: "#FF6B6C",
          data: []
        },
        {
          label: "Intervention",
          backgroundColor: "#dedede",
          data: []
        }
      ];

      res.data.data.forEach((element, index) => {
        if (element.length > 0) {
          data[0].data[index] = element[1];
          data[1].data[index] = element[0];
        } else {
          data[0].data[index] = 0;
          data[1].data[index] = 0;
        }
      });

      this.overallChart.data = {
        labels: res.data.labels,
        datasets: data
      };
    },
    async getAnalyticsTrend() {
      let params = this.$route.params.slug
        ? { project: this.$route.params.slug }
        : {};
      for (let [key, value] of Object.entries(this.modelTrend)) {
        if (value != null && value != "" && typeof value !== "object") {
          params[key] = value;
        } else if (value != null && typeof value == "object") {
          for (let [key2, value2] of Object.entries(value)) {
            params[key2] = value2;
          }
        }
      }
      let url = "analytics/trend?";
      var queryString = Object.keys(params)
        .map(key => key + "=" + params[key])
        .join("&");
      // console.log(JSON.stringify(queryString));
      console.log(`${url}${queryString}`);
      // console.log(params)
      const resTrend = await API.get(`${url}${queryString}`);
      // console.log(resTrend)
      this.trendChart.data = {
        labels: resTrend.data.labels,
        datasets: [
          {
            label: "Violations",
            backgroundColor: "#f87979cc",
            lineTension: 0,
            data: resTrend.data.datasets[0].data
          }
        ]
      };
    },
    async getAnalyticsTrendHourly() {
      let params = this.$route.params.slug
        ? { project: this.$route.params.slug }
        : {};
      for (let [key, value] of Object.entries(this.modelTrendHourly)) {
        if (value != null && value != "" && typeof value !== "object") {
          params[key] = value;
        } else if (value != null && typeof value == "object") {
          for (let [key2, value2] of Object.entries(value)) {
            params[key2] = value2;
          }
        }
      }
      // console.log(params)
      let url = "analytics/trendhourly?";
      var queryString = Object.keys(params)
        .map(key => key + "=" + params[key])
        .join("&");
      // console.log(JSON.stringify(queryString));
      console.log(`${url}${queryString}`);
      const resTrend = await API.get(`${url}${queryString}`);
      console.log(resTrend);
      this.trendHourlyChart.data = {
        labels: resTrend.data.labels,
        datasets: [
          {
            label: "Violations",
            backgroundColor: "#f87979cc",
            lineTension: 0,
            data: resTrend.data.datasets[0].data
          }
        ]
      };
    },

    async getAnalyticsPie() {
      let params = this.$route.params.slug
        ? { project: this.$route.params.slug }
        : {};
      for (let [key, value] of Object.entries(this.modelPie)) {
        if (value != null && value != "" && typeof value !== "object") {
          params[key] = value;
        } else if (value != null && typeof value == "object") {
          for (let [key2, value2] of Object.entries(value)) {
            params[key2] = value2;
          }
        }
      }
      // console.log(params)
      let url = "analytics/pie?";
      var queryString = Object.keys(params)
        .map(key => key + "=" + params[key])
        .join("&");
      // console.log(JSON.stringify(queryString));
      console.log(`${url}${queryString}`);
      const resPie = await API.get(`${url}${queryString}`);
      // console.log(resPie)

      const pieDataset = {
        labels: resPie.data.map(d => {
          return d.name;
        }),
        datasets: [
          {
            label: "",
            backgroundColor: resPie.data.map(d => {
              return d.color;
            }),
            data: resPie.data.map(d => {
              return d.count;
            })
          }
        ]
      };
      this.pieChart.data = pieDataset;
    },
    async getContractors() {
      const res = await API.get("contractors");
      // console.log('contractors')
      this.contractors = res.data;
    },
    async getClients() {
      const res = await API.get("clients");
      this.clients = res.data;
    },
    async getRanking() {
      let params = this.$route.params.slug
        ? { project: this.$route.params.slug }
        : {};
      console.log(params);
      let url = "rank?";
      var queryString = Object.keys(params)
        .map(key => key + "=" + params[key])
        .join("&");
      // console.log(JSON.stringify(queryString));
      console.log(`${url}${queryString}`);
      const res = await API.get(`${url}${queryString}`);
      console.log(res.data);
      this.rankings = res.data;
      // console.log(res)
      // this.contractors = res.data
    }
  },

  async mounted() {
    this.getContractors();
    this.getClients();
    // if(this.$route.params.slug){
    this.getAnalyticsTotal();
    this.getAnalyticsTrend();
    this.getAnalyticsTrendHourly();
    this.getAnalyticsPie();

    // this.getInterventions();
    this.getRanking();
    // }
  }
};
</script>

<style scoped>
.card {
  background-color: #fff;
}
</style>