<template>
  <modal id="userFormModal" title="Edit User" @close="close">
    <div class="form">
      <div class="row pb-4">
        <template v-for="input in inputs">
          <component
            :is="`${input.type}-input`"
            :key="input.id"
            :input="input"
            class="col-12"
            :class="`${
              input.col
                ? input.col == 0
                  ? 'col-sm'
                  : 'col-sm-'.concat(input.col)
                : 'col-sm-12'
            }`"
            @change="inputChanged"
          />
        </template>
      </div>
      <div
        class="pt-3 border-top d-flex justify-content-between align-items-center"
        v-if="isEditing"
      >
        <button
          class="btn btn-light px-4"
          :disabled="isProcessing"
          @click="close"
        >
          Cancel
        </button>
        <button
          class="btn btn-success px-4"
          :disabled="isProcessing"
          @click="save()"
        >
          <i class="fas fa-save mr-2"></i>
          Save
        </button>
      </div>
      <div class="row pt-3 border-top" v-else>
        <div class="col">
          <button
            class="btn btn-block btn-sm btn-info"
            :disabled="isProcessing"
            @click="isEditing = true"
          >
            Edit
          </button>
        </div>
        <div class="col">
          <button
            v-if="user && !user.confirmed"
            class="btn btn-block btn-sm btn-success"
            :disabled="isProcessing"
            @click="approveUser()"
          >
            Approve
          </button>
        </div>
        <div class="col">
          <button
            v-if="user && user.role && user.role.name != 'Admin'"
            class="btn btn-block btn-sm btn-primary"
            :disabled="isProcessing"
            @click="setAsAdmin()"
          >
            Set as Admin
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import API from "@/api";
import Modal from "@/components/Modal";
import TextInput from "@/components/inputs/Text";

export default {
  props: ["user", "roles"],
  components: {
    Modal,
    TextInput
  },
  data() {
    return {
      isEditing: false,
      isProcessing: false,
      inputs: [
        {
          type: "text",
          label: "Name",
          id: "name",
          model: "",
          disabled: true
        },
        {
          type: "text",
          label: "Department",
          id: "department",
          model: "",
          disabled: true
        }
      ]
    };
  },
  watch: {
    user: {
      handler() {
        this.inputs = this.inputs.map(input => {
          input.model =
            this.user && this.user.profile ? this.user.profile[input.id] : "";

          return input;
        });
      },
      deep: true,
      immediate: true
    },
    isEditing: {
      handler() {
        this.inputs = this.inputs.map(input => {
          input.disabled = !this.isEditing;

          return input;
        });
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    approveUser() {
      this.isProcessing = true;

      API.put(`users/${this.user.id}`, { confirmed: true })
        .then(retVal => {
          console.log(retVal)
          API.post(`emails/user-confirmed`, { email: this.user.email })
          .then(() => {
              this.isProcessing = false;
              this.close(retVal.data);
            }
          );
        })
        .catch(err => {
          console.error(err);

          this.isProcessing = false;
        });
    },
    setAsAdmin() {
      this.isProcessing = true;

      API.put(`users/${this.user.id}`, {
        role: this.roles.find(role => role.name == "Admin")
      })
        .then(retVal => {
          this.isProcessing = false;

          this.close(retVal.data);
        })
        .catch(err => {
          console.error(err);

          this.isProcessing = false;
        });
    },
    inputChanged(e) {
      let input = this.inputs.find(i => i.id == e.id);

      input.model = e.model;
    },
    close(e) {
      if (!this.isProcessing) {
        this.$emit("close", e);
      }
    },
    save() {
      this.isProcessing = true;

      let url = "profiles";
      let method = "post";

      let body = {};

      if (this.user.profile) {
        method = "put";
        url += `/${this.user.profile.id}`;
      } else {
        body.user = this.user.id;
      }

      this.inputs.forEach(input => {
        body[input.id] = input.model;
      });

      API[method](url, body)
        .then(retVal => {
          this.isProcessing = false;

          this.close(retVal.data.profile ? retVal.data.profile : retVal.data);
        })
        .catch(err => {
          console.error(err);

          this.isProcessing = false;
        });
    }
  }
};
</script>