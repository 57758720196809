<template>
  <nav class="navbar-sub shadow-sm py-0">
    <!-- <a class="navbar-brand" href="#" @click.prevent="goTo('Projects')">
      <img src="@/assets/images/logo.png" class="logo" />
    </a> -->
    <!-- <div class="d-flex align-items-center py-2 nav-item" v-if="this.$route.name != 'ChangePassword'">
      <div class="nav-divider h-100"></div>
    </div> -->
    <!-- <div class="ml-2" v-if="this.$route.name != 'admin.users' && this.$route.name != 'ChangePassword'">
      <select
        class="custom-select custom-select-sm nav-select"
        v-model="selectedProject"
        @change="projectChange()"
      >
        <option :value="null" disabled>Select a Project</option>
        <option
          v-for="option in projectOptios"
          :key="option.name"
          :value="option"
        >
          {{ option.name }}
        </option>
      </select>
    </div> -->
    <div class="w-100">
      <ul class="d-flex w-100 pl-2 overflow-auto">
        <li
          class="subnav-item main justify-content-start justify-content-md-center"
          :class="{ 'active-sub': isActiveNav('Home') ||
              isActiveNav('Camera') }"
          v-if="this.$route.params && this.$route.params.slug"
        >
          <a
            class="nav-link stretched-link"
            href="#"
            @click.prevent="goTo('Home')"
            >Camera
          </a>
        </li>
        <li
          class="subnav-item main justify-content-start justify-content-md-center"
          :class="{ 'active-sub': isActiveNav('Analytics') }"
          v-if="!userIsOperator && this.$route.params && this.$route.params.slug"
        >
          <a
            class="nav-link stretched-link"
            href="#"
            @click.prevent="goTo('Analytics')"
            >Analytics
          </a>
        </li>
        <li
          class="subnav-item main justify-content-start justify-content-md-center"
          :class="{ 'active-sub': isActiveNav('Activity') }"
          v-if="(userIsAdmin || userIsManagement && !userIsOperator) &&
                this.$route.params && this.$route.params.slug"
        >
          <a
            class="nav-link stretched-link"
            href="#"
            @click.prevent="goTo('Activity')"
            >Activity
          </a>
        </li>
        <li
          class="subnav-item main justify-content-start justify-content-md-center"
          :class="{ 'active-sub': isActiveNav('Report') }"
          v-if="this.$route.params && this.$route.params.slug"
        >
          <a
            class="nav-link stretched-link"
            href="#"
            @click.prevent="goTo('Report')"
            >Report
          </a>
        </li>
        <li class="d-block d-md-none">
          <div class="dropdown-divider"></div>
        </li>
        
      </ul>
    </div>
  </nav>
</template>

<script>
import $ from "jquery";
import API from "@/api.js";

export default {
  data() {
    return {
      user: null,
      projectOptios: [],
      selectedProject: null
    };
  },

  watch: {
    $route: {
      handler() {
        $(".navbar-collapse").removeClass("show");
        if (this.$route.name != "Projects") {
          var pslug = this.$route.params.slug
          this.selectedProject = this.projectOptios.find((project) =>  project.slug == pslug );
        } else {
          this.selectedProject = null;
        }
      },
      deep: true,
      immediate: true
    }
  },

  computed: {
    userIsAdmin() {
      return this.user && this.user.role && this.user.role.name == "Admin";
    },
    userIsOperator() {
      return this.user && this.user.role && this.user.role.name == "Operator";
    },
    userIsManagement() {
      return this.user && this.user.role && this.user.role.name == "Management";
    }
  },

  methods: {
    projectChange(){
      this.goTo("Home")
    },
    isActiveNav(name) {
      return this.$route.name == name;
    },

    goTo(name) {
      let prop = { name: name, params: {slug : null} }
      if(name == 'Home' || name == 'Analytics' || name == 'Activity' || name == 'Report'){
        prop.params.slug = this.selectedProject.slug 
      }
      console.log(prop)

      if (this.$route.name != name || this.$route.params != prop.params)  this.$router.push(prop)
    },

    async getProjects() {
      const res = await API.get(`projects?users=${this.user.id}`);
      this.projectOptios = res.data;
      if (this.$route.name != "Projects") {
        var pslug = this.$route.params.slug
        this.selectedProject = this.projectOptios.find((project) =>  project.slug == pslug );
      } else {
        this.selectedProject = null;
      }
    }
  },

  async mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    if(this.user){
      this.getProjects()
    }
  }
};
</script>