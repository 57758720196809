<template>
  <div class="form-group">
    <label :for="input.id" class="font-weight-medium default">
      {{ input.label }}
    </label>
    <input
      :type="input.type"
      :placeholder="input.placeholder"
      :disabled="input.disabled"
      class="form-control default"
      :id="input.id"
      v-model="model"
    />
  </div>
</template>

<script>
export default {
  name: "text-input-component",
  props: ["input"],
  data() {
    return {
      model: null,
    };
  },
  watch: {
    "input.model": {
      handler() {
        this.model = this.input.model;
      },
      deep: true,
      immediate: true,
    },
    model() {
      this.$emit("change", { id: this.input.id, model: this.model });
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  font-size: 13px;
}
</style>