<template>
  <div class="container-fluid p-0">
    <navbar />
    <subnavbar v-if="this.$route.params && this.$route.params.slug" />
    <slot />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import NavbarSub from "@/components/NavbarSub";

export default {
  components: {
    'subnavbar':NavbarSub,
    Navbar
  },
};
</script>
