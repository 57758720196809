<template>
  <div class="w-100">
    <div
      v-if="!violations"
      class="col-12 d-flex justify-content-center align-items-center py-4"
    >
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
    <div
      v-if="sortedViolations && !sortedViolations.length"
      class="col-12 d-flex justify-content-center align-items-center py-4"
    >
      <p class="m-0 p-0">No violation found so far</p>
    </div>
    <div class="w-100" v-if="sortedViolations">
      <div class="w-100" v-for="violation in sortedViolations" :key="violation.id">
        <div class="w-100 mb-3 border">
          <div class="w-100 bg-white px-3 py-2 row m-0">
            <div class="col-6 p-0">
              <p class="font-smaller font-weight-bolder m-0 violation-name">
                {{ violation.name }}
              </p>
              <p class="font-smaller font-weight-lighter m-0">
                {{ setDate(violation.timestamp) }}
              </p>
            </div>
            <div
              class="col-6 p-0 d-flex justify-content-end cursor-pointer"
              @click="intervene(violation)"
              v-if="!violation.interventions.length"
            >
              <p class="font-smaller font-weight-bold m-0 color-green">
                <font-awesome-icon :icon="['fas', 'volume-up']" class="mr-1" />
                Intervene
              </p>
            </div>
            <div class="col-6 p-0 d-flex justify-content-end"
              v-if="violation.interventions.length">
              <!-- Intervene by {{violation}} -->
              <!-- <p class="font-smaller font-weight-bold m-0 color-green">
                      </p> -->

              <p class="font-smaller font-weight-lighter m-0 text-right">
                Intervened by<br>{{violation.interventions[0].user.username}}
              </p>
            </div>
          </div>
          <div>
            <img
              :src="violation.image.replace('https://prsb-socket.inference.asia','https://aisc-media.sgp1.digitaloceanspaces.com')"
              class="w-100 cursor-pointer"
              alt=""
              @click="violationSelected(violation)"
            />
          </div>
          <div class="w-100 bg-white px-2 py-3 d-flex justify-content-end">
            <p class="font-smaller m-0 mr-2">Add to statistic</p>
            <label class="switch m-0">
              <input
                type="checkbox"
                :checked="violation.isCounted"
                @change="toggleViolation(violation)"
              />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api.js";
import DATE from "@/date.js";
import Swal from "sweetalert2";
import Pusher from "pusher-js";
// import moment from "moment";
export default {
  props: ["subwork", "camera"],
  data() {
    return {
      violations: null,
      isLoadinng: false,
      pusher: null,
    };
  },
  computed: {
    sortedViolations() {
      if (this.violations == null) return [];

      return [...this.violations].sort(
        (a, b) => b.timestamp - a.timestamp
      );
    }
  },
  methods:{
    setDate(date) {
      return DATE.unixToFull(date);
    },
    violationSelected(violation){
        this.$emit('violationSelected', violation)
    },
    // intervene(violation){
    //     this.$emit('intervene', violation)
    // },

    async toggleViolation(violation) {
      console.log(violation)
      const index = this.violations.indexOf(violation);
      console.log(index)
      this.violations[index].isCounted = !violation.isCounted;
      await API.put(`violations/${violation.id}`, {
        isCounted: this.violations[index].isCounted
      });
    },
    intervene(violation) {
      const streamUrl = this.camera.broadcasts[0].stream_url;
      const user = JSON.parse(localStorage.getItem("user"));
      const number = streamUrl.match(/\d+/g).join("");
      Swal.fire({
        title: "Confirmation",
        text: "Are you sure you want to notify the workers via speaker?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, notify them"
      }).then(result => {
        if (result.isConfirmed) {
          API.post("interventions/sound", {
            streamKey: `stream${number}`,
            violation: violation.id,
            userId: user.id
          }).then(() => {
            Swal.fire(
              "Notification",
              "Workers alerted successfully.",
              "success"
            );
            this.getViolations()
          });
        }
      })
    },
    getViolations(){
      console.log('getViolations')
            console.log(this.subwork.id)
        API.get(`subworkviolations?subwork=${this.subwork.id}&_sort=created_at:DESC`)
        .then(res => {
            console.log(res.data)
          this.violations = res.data;
        })
        .catch(err => {
          console.log(err);
          alert(`${err}`);
        });
    }
  },
  mounted(){
    console.log(this.subwork)
    this.getViolations()

    this.pusher = new Pusher("546809d823ede5cbc2d7", {
      cluster: "ap1",
      forceTLS: true
    });

    this.pusher.connection.bind("connected", () => {
      console.log("Pusher connected");
    });

    const channel = this.pusher.subscribe("aisc");

    channel.bind("violation", (data) => {
      console.log(data);
      // if (this.selectedSubwork == null) return;

      this.getViolations();
    });

    channel.bind("violation-deleted", (data) => {
      console.log(data);
      // if (this.selectedSubwork == null) return;

      this.getViolations();
    });
  },
  beforeDestroy() {
    if (this.pusher) this.pusher.disconnect();
  }
};
</script>

