import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Camera from '../views/Camera.vue'
import Analytics from '../views/Analytics.vue'
import Activity from '../views/Activity.vue'
import Report from '../views/Report.vue'
import Users from '../views/admin/Users.vue'
import Projects from '../views/Projects.vue'
import ChangePassword from '../views/ChangePassword.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('../views/Auth.vue'),
    meta: {
      guest: true,
      layout: 'no-navbar'
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../views/Signup.vue'),
    meta: {
      guest: true,
      layout: 'no-navbar'
    }
  },
  {
    path: '/forget',
    name: 'Forget',
    component: () => import('../views/Forget.vue'),
    meta: {
      guest: true,
      layout: 'no-navbar'
    }
  },
  {
    path: '/resetpassword',
    name: 'Reset',
    component: () => import('../views/Reset.vue'),
    meta: {
      guest: true,
      layout: 'no-navbar'
    }
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/',
    name: 'Projects',
    component: Projects,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Analytics',
    name: 'MainAnalytics',
    component: Analytics,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/:slug/cameras',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/:slug/analytics',
    name: 'Analytics',
    component: Analytics,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/:slug/activities',
    name: 'Activity',
    component: Activity,
    meta: {
      requiresAuth: true,
      requiresManagementRole: true
    }
  },
  {
    path: '/projects/:slug/reports',
    name: 'Report',
    component: Report,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/:slug/cameras/:id',
    name: 'Camera',
    component: Camera,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/users',
    name: 'admin.users',
    component: Users,
    meta: {
      requiresAuth: true,
      layout: 'admin'
    }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('token') == null) {
      next({
        path: '/auth',
        params: {
          nextUrl: to.fullPath
        }
      })
    } else {
      let user = JSON.parse(localStorage.getItem('user'))

      if (to.matched.some(record => record.meta.isAdmin)) {
        if (user.role.name === 'Admin') {
          next()
        } else {
          next({
            name: 'Projects'
          })
        }
      } else {
        next()
      }

      if (to.matched.some(record => record.meta.requiresManagementRole)) {
        if (user.role.name === 'Management' || user.role.name === 'Admin') {
          next()
        } else {
          next({
            name: 'Projects'
          })
        }
      } else {
        next()
      }
    }

  } else if (to.matched.some(record => record.meta.guest)) {
    if (localStorage.getItem('token') == null) {
      next()
    } else {
      next({
        name: 'Projects'
      })
    }
  } else {
    next()
  }
})

export default router
