<template>
  <div class="custom-modal" :class="{ 'custom-modal-full': isFull }">
    <div class="custom-modal-content">
      <div class="custom-modal-header">
        <h3>{{ title }}</h3>
        <button class="btn btn-modal-close" @click="close()">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="times"
            class="svg-inline--fa fa-times fa-w-11"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 352 512"
          >
            <path
              fill="currentColor"
              d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
            ></path>
          </svg>
        </button>
      </div>
      <div class="custom-modal-body">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "modal-component",
  props: ["title", "isFull", "id"],
  data() {
    return {
      result: {
        show: false,
        data: null,
      },
      body: null,
    };
  },
  methods: {
    close() {
      $("body").removeClass("modal-open");

      this.$emit("close", this.result);
    },
  },
  mounted() {
    $("body").addClass("modal-open");
  },
};
</script>

<style lang="scss" scoped>
$shadow: rgba(0, 0, 0, 0.15);

.custom-modal {
  display: block;
  position: fixed;
  top: 0px;
  left: 0px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100vw;
  height: 100vh;
  background-color: transparentize($color: #343a40, $amount: 0.3);
  z-index: 99;

  .custom-modal-content {
    display: block;
    width: 100%;
    max-width: 500px;
    background-color: #ffffff;
    border-radius: 3px;
    margin: 0 auto 20px auto;
    box-shadow: 1px 1px 4px $shadow;

    .custom-modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 2rem;
      box-shadow: 0px 1px 2px $shadow;

      h3 {
        font-size: 1.1rem;
        font-weight: 700;
        margin-bottom: 0px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .btn-modal-close {
        height: 30px;
        width: 30px;
        border: 1px solid #dedede;
        box-shadow: 1px 1px 4px $shadow;
        border-radius: 15px;
        padding: 3px 8px;
      }
    }

    .custom-modal-body {
      padding: 2rem;
    }
  }

  &.custom-modal-full {
    overflow-y: hidden;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;

    .custom-modal-content {
      width: 100%;
      max-width: 100%;
      height: 100%;
      margin: 0px;
    }

    .custom-modal-body {
      height: calc(100% - 56px);
    }
  }
}
</style>