<template>
  <div class="px-3 py-1 py-md-4">
    <h3 class="pl-4 pl-md-0 py-2">Manage Users</h3>
    <div class="table-responsive shadow-sm">
      <table class="table table-sm table-bordered table-custom">
        <thead>
          <tr>
            <th style="width: 1%"></th>
            <th>Email</th>
            <th>Name</th>
            <th>Department</th>
            <th>Role</th>
            <th>Projects</th>
            <th>Approval Status</th>
            <th>Account Status</th>
            <th style="width: 1%"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.id">
            <td class="px-0" style="width: 1%">
              <button
                class="btn btn-sm btn-link py-0 text-info"
                @click="editUser(user)"
              >
                <font-awesome-icon :icon="['fas', 'edit']" />
              </button>
            </td>
            <td>{{ user.email }}</td>
            <td>{{ user.profile && user.profile.name }}</td>
            <td>{{ user.profile && user.profile.department }}</td>
            <td>{{ user.role && user.role.name }}</td>
            <td>{{ user.projects.map(p => p.name).join(', ') }}</td>
            <td
              v-if="user && user.confirmed"
              class="text-success font-weight-medium"
            >
              Approved
            </td>
            <td v-else>Pending</td>

            <td
              v-if="user && !user.blocked"
              class="text-success font-weight-medium"
            >
              Active
            </td>
            <td v-else>Disabled</td>
            <td class="px-0" style="width: 1%">
              <button
                class="btn btn-sm btn-link py-0 text-danger"
                @click="deleteUser(user)"
              >
                <font-awesome-icon :icon="['fas', 'trash-alt']" />
              </button>
            </td>
          </tr>
          <tr v-if="users.length < 1">
            <td colspan="8" class="text-center font-italic text-muted">
              No user found
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <user-form-modal
      v-if="showModal"
      @close="closeModal"
      :user="currentUser"
      :roles="roles"
    />
  </div>
</template>

<script>
import API from "@/api";
import Swal from "sweetalert2";
import UserFormModal from "@/components/modals/UserForm.vue";

export default {
  components: {
    UserFormModal,
  },
  data() {
    return {
      users: [],
      roles: [],
      showModal: false,
      currentUser: null,
    };
  },
  methods: {
    deleteUser(user) {
      Swal.fire({
        html: `<p>Confirm delete this user?</p><p class='font-weight-bold'>${user.username}</p>`,
        icon: "question",
        confirmButtonText: "Confirm",
        showCancelButton: true,
        buttonsStyling: false,
        reverseButtons: true,
        customClass: {
          confirmButton: "btn btn-sm btn-danger",
          cancelButton: "btn btn-sm btn-light",
          actions: "d-flex justify-content-between align-items-center",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          await API.delete(`users/${user.id}`);

          this.users = this.users.filter((u) => u.id != user.id);
        }
      });
    },
    editUser(user) {
      console.log(user)
      this.currentUser = user;

      this.$nextTick(() => {
        this.showModal = true;
      });
    },
    closeModal(e) {
      if (e) {
        this.getUsers();
      }

      this.currentUser = null;
      this.showModal = false;
    },
    getUsers() {
      API.get("users")
        .then((retVal) => {
          this.users = retVal.data;
          console.log(this.users)
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getRoles() {
      API.get("users-permissions/roles")
        .then((retVal) => {
          this.roles = retVal.data.roles;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  mounted() {
    this.getUsers();
    this.getRoles();
  },
};
</script>