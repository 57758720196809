<template>
    <div class="page-default padding-subnav">
        <div class="container-fluid  overflow-auto h-100 px-3 py-2">
            <div class="mt-2 p-4 " style="height: 100%">
                <h5>List of Users' Activities</h5>
                <table v-if="users.length > 0" class="table table-hover mt-4">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Last Active</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(user, ind) in users" :key="user.id">
                            <td scope="row">{{ ind+1 }}</td>
                            <td>{{ user.username }}</td>
                            <td>{{ user.email }}</td>
                            <td>{{ getUserLastActivity(user.lastactivity) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import API from "@/api";
import moment from 'moment'
export default {
    data(){
        return {
            project: null,
            users: []
        }
    },
    methods: {
        getUserLastActivity(_lastactivity){
            if(!_lastactivity) return 'N/A'
            else return moment(_lastactivity).format("ddd, MMM Do YYYY, h:mm:ss a")
        }
    },
    async mounted(){
        let projectRes = await API.get(`projects?slug=${this.$route.params.slug}`)
        let usersRes = await API.get(`users?projects=${projectRes.data[0].id}`)
        this.project = projectRes.data[0]
        this.users = usersRes.data
    }
}
</script>