<template>
    <div>
        <div class="page-default">
            <div class="container-fluid  overflow-auto h-100 px-3 py-2">
                <div class="row mt-2">
                    <div class="col-lg-4 offset-lg-4">
                        <div class="card">
                            <div class="container">
                                <div class="card-body text-center">
                                    <h6 class="mb-3 d-block">Change Password</h6>
                                    <form id="auth-form">
                                        <div class="form-group mb-2">
                                            <input type="password" placeholder="***" id="currentPassword" v-model="currentPassword" class="form-control">
                                            <label for="currentPassword" class="form-control-label">Current Password</label>
                                        </div>
                                        <div class="form-group mb-2">
                                            <input type="password" placeholder="***" id="newPassword" v-model="newPassword" class="form-control">
                                            <label for="newPassword" class="form-control-label">New Password</label>
                                        </div>
                                        <div class="form-group mb-2">
                                            <input type="password" placeholder="***" id="confirmNewPassword" v-model="confirmNewPassword" class="form-control">
                                            <label for="confirmNewPassword" class="form-control-label">Confirm New Password</label>
                                        </div>
                                        <button type="submit" @click="submitResetPassword" class="btn w-100">
                                            <span>Change Password</span>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import API from './../api'
export default {
    data(){
        return {
            user: null,
            currentPassword: null,
            newPassword: null,
            confirmNewPassword: null
        }
    },
    methods: {
        async submitResetPassword(){
            try {
                if(!this.currentPassword) throw 'Please insert your current password!'
                else if(!this.newPassword) throw 'Please insert your new password!'
                else if(!this.confirmNewPassword) throw 'Please confirm your new password!'

                if(this.newPassword != this.confirmNewPassword) throw 'Your confirm password is unmatch.'
                if(this.currentPassword == this.newPassword) throw 'Please insert different password for your new password.'

                // let res = await API.post('password/reset', {identifier: this.user.email, password: this.newPassword})
                // console.log('ok')
                // console.log(res.data)
                API.post('password/reset', {identifier: this.user.email, password: this.currentPassword, newPassword: this.newPassword})
                .then(user => {
                    console.log('ok')
                    console.log(user.data)

                    //sign out
                    localStorage.removeItem("user");
                    localStorage.removeItem("token");
                    window.location.reload()
                })
                .catch(err => {
                    if(err.response){
                        if(err.response.status == 400){
                            console.error('Error : ', err.response.data.message[0].messages[0].message)
                        }
                    }
                })
            } catch (error) {
                alert(error)
            }
        }
    },
    async mounted(){
        this.user = JSON.parse(localStorage.getItem("user"))
    }
}
</script>

<style scoped>
input.form-control:not(.default) {
    padding-left: 9rem;
}
</style>