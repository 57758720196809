import axios from 'axios'

export default class API {
	static getExternal(url, type) {
		return new Promise( (resolve, reject) => {
			try {
				const opts = {
					method: "get",
					url: url
				};
				if (type == 'file') {
					opts.responseType = 'blob'
				}
				axios(opts).then(response => resolve(response))
				// resolve(response);
			} catch (err) {
				console.error(JSON.stringify(err.response));
				reject(err);
			}
		})
	}

	static get(moduleName, type) {
		return new Promise((resolve, reject) => {
			try {
				const opts = this._opts(moduleName, 'get');
				if (type == 'file') {
					opts.responseType = 'blob'
				}
				axios(opts).then(response => resolve(response))
				
			} catch (err) {
				console.error(JSON.stringify(err.response));
				if (err.response && err.response.status == 401) {
					localStorage.clear();
					window.location = '/';
				}
				reject(err);
			}
		})
	}

	static post(moduleName, data) {
		return new Promise((resolve, reject) => {
			try {
				const opts = this._opts(moduleName, 'post', data);
				axios(opts).then(response => resolve(response)).catch(err => {reject(err)})
			} catch (err) {
				console.error(JSON.stringify(err.response));
				if (err.response && err.response.status == 401) {
					localStorage.clear();
					window.location = '/';
				}
				reject(err);
			}
		})
	}

	static postForm(moduleName, data) {
		return new Promise((resolve, reject) => {
			try {
				const opts = this._opts(moduleName, 'post', data);
				opts.headers['Content-Type'] = 'multipart/form-data';
				axios(opts).then(response => resolve(response))
			} catch (err) {
				if (err.response && err.response.status == 401) {
					localStorage.clear();
					window.location = '/';
				}
				reject(err);
			}
		})
	}

	static put(moduleName, data) {
		return new Promise((resolve, reject) => {
			try {
				const opts = this._opts(moduleName, 'put', data);
				axios(opts).then(response => resolve(response)).catch(err => {reject(err)})
			} catch (err) {
				if (err.response && err.response.status == 401) {
					localStorage.clear();
					window.location = '/';
				}
				reject(err);
			}
		})
	}

	static delete(moduleName) {
		return new Promise((resolve, reject) => {
			try {
				const opts = this._opts(moduleName, 'delete');
				axios(opts).then(response => resolve(response))
			} catch (err) {
				if (err.response && err.response.status == 401) {
					localStorage.clear();
					window.location = '/';
				}
				reject(err);
			}
		})
	}

	static _opts(moduleName, method, data) {
		let token = localStorage.getItem('token');
		const opts = {
			method: method,
			url: `${this.apiUrl()}/${moduleName}`
		};

		if (token) {
			opts.headers = {
				'Authorization': `Bearer ${token}`
			};
		}

		if (data) {
			opts.data = data
		}

		return opts;
	}

	static apiUrl() {
		return process.env.VUE_APP_API_URL;
	}
}