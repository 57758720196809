<template>
  <div class="container-fluid p-0">
    <navbar />
    <div class="page-default layout-admin row mx-0">
      <div
        class="col-12 col-md-3 admin-sidebar bg-darkgreen"
        :class="{ show: showSidebarOnMobile }"
      >
        <div
          class="mb-0 p-3 text-muted sidebar-title font-weight-medium d-flex justify-content-between align-items-center"
        >
          <span>ADMIN</span>
          <button
            class="btn btn-outline-light border-0 py-0 sidebar-close-btn"
            @click="toggleMobileNavbar()"
          >
            <font-awesome-icon :icon="['fas', 'times']" />
          </button>
        </div>
        <div class="list-group">
          <div
            class="list-group-item font-weight-medium"
            v-for="link in links"
            :key="link.label"
          >
            <div>
              <font-awesome-icon
                :icon="link.icon || ['fas', 'list-ul']"
                class="mr-2"
              />
              {{ link.label }}
            </div>
            <div class="list-group">
              <router-link
                class="list-group-item font-weight-medium"
                v-for="item in link.items"
                :key="item.label"
                :to="{ name: item.routeName }"
              >
                {{ item.label }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col admin-content" :class="{ show: showSidebarOnMobile }">
        <button class="btn sidebar-toggle-btn" @click="toggleMobileNavbar()">
          <font-awesome-icon :icon="['fab', 'elementor']" />
        </button>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";

export default {
  components: {
    Navbar,
  },
  data() {
    return {
      showSidebarOnMobile: false,
      links: [
        {
          label: "Settings",
          icon: ["fas", "cogs"],
          items: [
            {
              label: "Users",
              routeName: "admin.users",
            },
          ],
        },
      ],
    };
  },
  watch: {
    "$route.name": {
      handler() {
        this.showSidebarOnMobile = false;
      },
      immediate: true,
    },
  },
  methods: {
    toggleMobileNavbar() {
      this.showSidebarOnMobile = !this.showSidebarOnMobile;
    },
  },
};
</script>
