<template>
  <div class="page-default padding-subnav">
    <div class="container-fluid h-100 p-2 overflow-auto">
      <!-- <div class="d-flex justify-content-start align-items-center p-3 mb-2 goback border-bottom">
        <font-awesome-icon
          :icon="['fa', 'arrow-left']"
          class="mr-2 font-large font-weight-bold"
          @click="goTo('Projects')"
        />
        <p
          class="font-weight-normal m-0 body cursor-pointer left-title"
          @click="goTo('Projects')"
        >
          DELIMA Project, MRCSB
        </p>
      </div> -->
      <div v-if="cameras" class="row m-0">
        <div
          v-for="camera in cameras"
          :key="camera.id"
          class="col-12 col-md-6 col-lg-3 p-2"
        >
          <div class="card" @click="cameraClick(camera)">
            <div class="card-img-top p-1 camera-img-container">
              <img
                class="w-100"
                :src="`${camera.broadcasts[0].stream_url}?${cacheKey}`"
                alt=""
              />
            </div>
            <div
              class="card-body px-3 py-2 d-flex justify-content-between align-items-center"
            >
              <p class="card-text m-0 font-weight-bolder title">
                {{ camera.name }}
              </p>
              <p class="card-text m-0 body font-weight-">
                <font-awesome-icon
                  :icon="['fas', 'map-marker-alt']"
                  class="mr-2"
                />{{ camera.area }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import $ from "jquery";
import API from "@/api.js";
export default {
  data() {
    return {
      cameras: null,
      cacheKey: +new Date(),
      timer: null
    };
  },
  watch: {
    $route: {
      handler() {
        $(".navbar-collapse").removeClass("show");
        if (this.$route.name == "Home") {
          this.getCameras()
        } 
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    resizeVideo() {
      const cameraContainer = $(".card-img-top");

      if (cameraContainer.length > 0) {
        const containerWidth = cameraContainer.innerWidth();

        const containerHeight = (containerWidth / 16) * 9;

        cameraContainer.innerHeight(containerHeight);
      }
    },

    // imageStyle() {
    // 	// const height = (window.innerWidth / 4) - 173

    // 	// return {
    // 	// 	height: `${height}px`,
    // 	// 	minHeight: `${height}px`,
    // 	// 	maxHeight: `${height}px`,
    // 	// 	overflow: 'hidden'
    // 	// }
    // },

    async getCameras() {

      if(!this.$route.params || !this.$route.params.slug) return

      let slug = this.$route.params.slug
      console.log(slug)
      const pres = await API.get(`projects?slug=${slug}`);
      let project = pres.data[0]

      const res = await API.get(`cameras?project=${project.id}`);

      this.cameras = res.data
      // .sort((a, b) => {
      //   const nameA = a.name.replace(/\D/g,'') ? parseInt(a.name.replace(/\D/g,'')) : a.name.toUpperCase();
      //   const nameB = b.name.replace(/\D/g,'') ? parseInt(b.name.replace(/\D/g,'')) : b.name.toUpperCase();
      //   return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
      // });

      this.$nextTick(() => {
        this.resizeVideo();
      });
    },
    loadError(url) {
      console.log("error " + url);
    },
    cameraClick(camera) {
      this.$router.push({
        name: "Camera",
        params: { id: camera.id }
      });
    },
    goTo(name) {
      if (this.$route.name != name) {
        this.$router.push({
          name: name
        });
      }
    }
  },
  mounted() {
    this.getCameras();
    this.cacheKey = +new Date();
    this.timer = setInterval(() => {
      this.cacheKey = +new Date();
      console.log(this.cacheKey);
    }, 10 * 1000);

    addEventListener("resize", this.resizeVideo);
  },

  beforeDestroy() {
    clearInterval(this.timer);

    removeEventListener("resize", this.resizeVideo);
  }
};
</script>
