<template>
  <div class="media-preview">
    <div class="media-header">
      <div>
        <h1 class="d-flex justify-content-start align-items-center">
          {{ title }}
        </h1>
        <!-- <h3>
          {{ subtitle }}
        </h3> -->
      </div>
      <button class="btn-close" @click="closePreview">&times;</button>
    </div>
    <div class="media-container">
      <div class="placeholder" v-if="isLoading">
        <div class="spinner-border text-primary mr-3"></div>
        <span class="text-muted">Loading preview...</span>
      </div>
      <div id="osd" :class="{ invisible: isLoading }"></div>
    </div>
  </div>
</template>

<script>
import OSD from "openseadragon";
export default {
  name: "media-preview",
  props: ["url", "title", "subtitle"],
  data() {
    return {
      osd: null,
      isLoading: false
    };
  },
  methods: {
    closePreview() {
      this.$emit("close");
    },
    getOS() {
      var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
        windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
        iosPlatforms = ["iPhone", "iPad", "iPod"],
        os = null;

      if (macosPlatforms.indexOf(platform) !== -1) {
        os = "Mac OS";
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = "iOS";
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = "Windows";
      } else if (/Android/.test(userAgent)) {
        os = "Android";
      } else if (!os && /Linux/.test(platform)) {
        os = "Linux";
      }

      return os;
    }
  },
  mounted() {
    this.isLoading = true;
    this.osd = OSD({
      id: "osd",
      prefixUrl: "/osd/",
      crossOriginPolicy: "Anonymous",
      maxZoomPixelRatio: 10
    }).open({
      type: "image",
      url: `${this.url}`
    });
    let os = this.getOS();
    if (os == "iOS" || os == "Android") {
      this.osd.zoomPerClick = 1;
    }

    this.osd.addHandler("open", res => {
      console.log(res);
      this.isLoading = false;
    });
  }
};
</script>