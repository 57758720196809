<template>
  <div class="page-default padding-subnav">
    <div class="container-fluid overflow-auto h-100 px-3 pb-2">
      <div class="mt-2 py-4" style="height: 100%">
        <div class="container">
          <div class="mb-2">
            <h5>Violation Report</h5>
          </div>
          <div
            class="
              align-items-start
              d-flex
              justify-content-center
              align-items-center
              py-5
            "
            v-if="isLoading"
          >
            <div class="spinner-border text-secondary mr-3"></div>
            Loading...
          </div>
          <template v-else>
            <div class="w-100 mb-2 pt-2 sticky-top" style="z-index: 2;  background-color: #242424;">
              <div class="row">
                <div class="col-6 col-lg-3 py-2" v-if="camerasOption">
                  <select
                    class="
                      form-control form-control-sm
                      custom-select custom-select-outline custom-select-sm
                    "
                    v-model="model.camera"
                  >
                    <option :value="null">All Cameras</option>
                    <option
                      v-for="camOption in camerasOption"
                      :key="camOption.id"
                      :value="camOption"
                    >
                      {{ camOption.name }}
                    </option>
                  </select>
                </div>
                <div class="col-6 col-lg-3 py-2">
                  <select
                    class="
                      form-control form-control-sm
                      custom-select custom-select-outline  custom-select-sm
                    "
                    v-model="model.parameter"
                  >
                    <option :value="null">All Parameters</option>
                    <option
                      v-for="option in parametersOptions"
                      :key="option"
                      :value="option"
                    >
                      {{ option }}
                    </option>
                  </select>
                </div>
                <div class="col-12 col-sm-6 col-lg-3 py-2">
                  <div class="dropdown h-100">
                    <button
                      id="dropdownMenuButton"
                      type="button"
                      class="
                        h-100
                        btn btn-sm
                        custom-select
                        custom-select-outline 
                        w-100
                        dropdown-toggle
                        d-flex
                        justify-content-start
                        align-items-center
                        no-pseudo
                        overflow-hidden
                      "
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <font-awesome-icon
                        :icon="['far', 'calendar-alt']"
                        class="mr-2"
                      />
                      <small
                        v-if="model.date.start && model.date.end"
                        class="d-inline-block text-truncate"
                      >
                        {{
                          `${setDate(model.date.start, "date")} - ${setDate(
                            model.date.end,
                            "date"
                          )}`
                        }}
                      </small>
                      <small class="d-inline-block text-truncate" v-else>
                        No date selected
                      </small>
                    </button>
                    <div
                      id="dropdown-menu"
                      class="dropdown-menu px-2"
                      @click.stop
                    >
                      <div
                        class="
                          dropdown-item-text
                          d-flex
                          justify-content-between
                          align-items-center
                          px-0
                          pb-2
                        "
                      >
                        <p
                          class="
                            badge badge-primary
                            mb-0
                            font-weight-normal
                            p-2
                          "
                        >
                          Filter by date
                        </p>
                      </div>
                      <v-date-picker
                        class="inline-calendar"
                        v-model="model.date"
                        :model-config="{
                          type: 'string',
                          mask: 'YYYY-MM-DD',
                        }"
                        mode="date"
                        trim-weeks
                        is-range
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="
                    col-12 col-sm-6 col-lg-3
                    py-2
                    d-flex
                    justify-content-between
                    align-items-center
                    pl-3 pl-md-0
                  "
                >
                  <button
                    class="btn btn-link btn-sm text-muted p-0"
                    :class="{
                      invisible:
                        !model.camera &&
                        !model.parameter &&
                        !model.date.start &&
                        !model.date.end,
                    }"
                    @click="clearFilter()"
                  >
                    <small>Reset</small>
                  </button>
                  <button
                    class="btn btn-primary btn-sm px-3 text-nowrap"
                    @click="downloadData()"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'file-download']"
                      class="mr-2"
                    />
                    Export to Excel
                  </button>
                </div>
              </div>
            </div>
            <div class="row mx-0">
              <div class="h-100 w-100" v-if="cameras && cameras.length">
                <div
                  class="w-100 mb-3 rounded"
                  v-for="camera in cameras"
                  :key="camera.id"
                >
                  <div
                    class="
                      w-100
                      cam-name                                            
                      px-3
                      py-2
                      mb-3
                      sticky-top
                      violation-header
                    "
                    style="z-index: 1; "
                  >
                    <p class="font-weight-medium text-uppercase mb-0">
											<font-awesome-icon
											:icon="['fas', 'camera']"
											class="mr-2"
										/>
                      {{ camera.camName }}
                    </p>
                  </div>
                  <div
                    class="
                      w-100
                      p-3
                      d-flex
                      justify-content-start
                      text-secondary
                    "
                    v-if="!camera.violations.length"
                  >
                    <p class="m-0">No violations for this camera</p>
                  </div>
                  <div class="row pb-2" v-if="camera.violations.length">
                    <div
                      class="col-12 col-md-4 col-lg-4"
                      v-for="violation in camera.violations"
                      :key="violation.id"
                    >
                      <div class="w-100 mb-3 border">
                        <div class="w-100 bg-white px-3 py-2 row m-0">
                          <div class="col-6 p-0">
                            <p
                              class="
                                font-smaller font-weight-bolder
                                m-0
                                violation-name
                              "
                            >
                              {{ violation.name }}
                            </p>
                            <p class="font-smaller font-weight-lighter m-0">
                              {{ setDate(violation.created_at) }}
                            </p>
                          </div>
                          <!-- <div
                            class="
                              col-6
                              p-0
                              d-flex
                              justify-content-end
                              cursor-pointer
                            "
                            v-if="!violation.interventions.length"
                          >
                            <p
                              class="
                                font-smaller font-weight-bold
                                m-0
                                color-green
                              "
                            >
                              <font-awesome-icon
                                :icon="['fas', 'volume-up']"
                                class="mr-1"
                              />
                              Intervene
                            </p>
                          </div> -->
                          <!-- <div
                            class="col-6 p-0 d-flex justify-content-end"
                            v-if="violation.interventions.length"
                          >
                            <p
                              class="
                                font-smaller font-weight-lighter
                                m-0
                                text-right
                              "
                            >
                              Intervened by<br />{{
                                violation.interventions[0].user.username
                              }}
                            </p>
                          </div> -->
                        </div>
                        <div>
                          <img
                            :src="
                              violation.image.replace(
                                'https://prsb-socket.inference.asia',
                                'https://aisc-media.sgp1.digitaloceanspaces.com'
                              )
                            "
                            @click="selectedViolation = violation"
                            class="w-100 cursor-pointer"
                            alt=""
                          />
                        </div>
                        <!-- <div
                          class="
                            w-100
                            bg-white
                            px-2
                            py-3
                            d-flex
                            justify-content-end
                          "
                        >
                          <p class="font-smaller m-0 mr-2">Add to statistic</p>
                          <label class="switch m-0">
                            <input
                              type="checkbox"
                              :checked="violation.isCounted"
                              @change="toggleViolation(violation)"
                            />
                            <span class="slider round"></span>
                          </label>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <media-preview
      v-if="selectedViolation"
      :url="setImageUrl(selectedViolation.image)"
      @close="selectedViolation = null"
      :title="`${selectedViolation.work.name} · ${selectedViolation.name}`"
    />
  </div>
</template>

<script>
import API from "@/api.js";
import moment from "moment";
import DATE from "@/date.js";
import XLSX from "xlsx";
import MediaPreview from "@/components/MediaPreview";

export default {
  components: { MediaPreview },
  data() {
    return {
      violations: null,
      selectedViolation: null,
      cameras: [],
      isLoading: false,
      camerasOption: [],
      parametersOptions: [
        "No Helmet",
        "No Mask",
        "No Welding Mask",
        "No Goggles",
        "No Coverall",
        "No Glove",
        "No Shoes",
        "Unauthorized Person",
        "No Gas Mask",
        "No Earmuff",
        "Phone",
        "Smoking",
        "No Standby Person",
        "No Fire Extinguisher",
        "Speed Limit",
      ],
      model: {
        date: {
          start: moment().format("YYYY-MM-DD"),
          end: moment().format("YYYY-MM-DD"),
        },
        camera: null,
        parameter: null,
      },
      masks: {
        input: "YYYY-MM-DD h:mm A",
      },
    };
  },
  watch: {
    model: {
      handler() {
        this.getViolations();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setImageUrl(url) {
      return url.includes("https://prsb-socket.inference.asia")
        ? url.replace(
            "https://prsb-socket.inference.asia",
            "https://aisc-media.sgp1.digitaloceanspaces.com"
          )
        : url.replace(
            "https://aisc-media.sgp1.cdn.digitaloceanspaces.com",
            "https://aisc-media.sgp1.digitaloceanspaces.com"
          );
    },
    clearFilter() {
      this.model = {
        date: {
          start: moment().format("YYYY-MM-DD"),
          end: moment().format("YYYY-MM-DD"),
        },
        camera: null,
        parameter: null,
      };
    },
    downloadData() {
      const dataPoints = this.violations.map((violation, index) => {
        return {
          num: index + 1,
          camera: violation.work ? violation.work.name : "",
          violation: violation.name,
          imageUrl: violation.image,
          timestamp: DATE.full(violation.created_at),
        };
      });

      dataPoints.unshift({
        num: "No.",
        camera: "Camera",
        violation: "Violation",
        imageUrl: "Image",
        timestamp: "Date & Time",
      });

      const ws = XLSX.utils.json_to_sheet(dataPoints, {
        skipHeader: true,
        dateNF: "YYYYMMDD HH:mm:ss",
      });

      const wbb = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wbb, ws, "filename");
      XLSX.writeFile(wbb, "data.xlsx");
    },
    setDate(date, type) {
      return DATE[type || "full"](date);
    },
    async getCameras() {
      let slug = this.$route.params.slug;
      // console.log(this.violations)
      const pres = await API.get(`projects?slug=${slug}`);
      let project = pres.data[0];

      this.camerasOption = [];
      this.cameras = [];

      await API.get(`cameras?project=${project.id}&_sort=name:ASC`)
        .then((res) => {
          // console.log(res.data);
          const self = this;
          res.data.forEach((element) => {
            this.camerasOption.push(element);

            if (!this.model.camera || element.id == this.model.camera.id) {
              this.cameras.push({
                id: element.id,
                camName: element.name,
                violations: self.violations.filter(
                  (i) => i.work.id == element.id
                ),
              });
            }
          });
          // console.log(this.camerasOption);
          this.isLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.isLoading = false;
        });
    },
    getViolations() {
      this.isLoading = true;

      let url = `violations?_limit=-1&isCounted=true`;

      if (this.model.camera) {
        url += `&work=${this.model.camera.id}`;
      }

      if (this.model.date.start && this.model.date.end) {
        const startDay = moment(this.model.date.start)
          .startOf("day")
          .toISOString();
        const endDay = moment(this.model.date.end).endOf("day").toISOString();

        url += `&created_at_gte=${startDay}&created_at_lte=${endDay}`;
      }

      API.get(url)
        .then((res) => {
          this.violations = res.data.filter(
            (v) =>
              !this.model.parameter ||
              Object.keys(v.count).includes(this.model.parameter)
          );
          this.getCameras();
        })
        .catch((err) => {
          this.isLoading = false;
          console.error(err);
          alert(`${err}`);
        });
    },
  },
};
</script>