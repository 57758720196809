<template>
  <div
    class="w-100"
    style="
      position: absolute;
      top: 0;
      height: calc(100% - 50px);
      margin-top: 50px;
      background: white;
    "
  >
    <div class="media-preview position-relative bg-white h-100">
      <div
        class="media-header shadow-none align-items-center justify-content-start"
      >
        <button class="btn btn-sm bg-white border-0 mr-2" @click="hide">
          <font-awesome-icon
            :icon="['fa', 'arrow-left']"
            style="vertical-align: middle"
            class="font-large font-weight-bold"
          />
        </button>
        <div>
          <h1 class="d-flex justify-content-start align-items-center">
            Interventions
          </h1>
        </div>
      </div>
      <div class="media-container pb-3 overflow-auto">
        <div class="placeholder align-items-start" v-if="isLoading">
          <div class="spinner-border text-secondary mr-3"></div>
        </div>
        <div class="h-100" v-if="cameras.length">
          <div class="w-100 mb-3" v-for="camera in cameras" :key="camera.id">
            <div class="w-100 border-bottom mb-2">
              <h5 class="font-weight-bold">{{ camera.camName }}</h5>
            </div>
            <div class="w-100 p-3 d-flex justify-content-start text-secondary" v-if="!camera.interventions.length">
                <p class="m-0">No interventions for this camera</p>
            </div>
            <div class="row m-0 pb-2" v-if="camera.interventions.length">
              <div class="col-12 col-sm-6 col-md-6 col-lg-2 w-100 p-2"
                  v-for="intervention in camera.interventions"
                  :key="intervention.id">
                <div
                  class="card"
                  style="border-radius: unset; cursor: auto;"
                >
                  <div class="card-img-top w-100 p-1">
                    <img
                      :src="intervention.violation.image"
                      class="w-100"
                      alt=""
                    />
                  </div>
                  <div
                    class="card-body w-100 px-2 py-0"
                  >
                    <p class="m-0 font-smaller overflow-hidden" style=" white-space: nowrap;">
                      {{ intervention.violation.name }}
                    </p>
                    <div class="mb-1" style="color: #bfc0bf">
                      <p class="m-0 font-italic overflow-hidden" style="font-size: 12px; white-space: nowrap;">
                        by {{ intervention.user.username }}
                      </p>
                      <p
                        class="m-0 font-italic overflow-hidden"
                        style="font-size: 12px; margin-top: -5px !importantl; white-space: nowrap;"
                      >
                        {{ setDate(intervention.created_at) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div
            style="height: calc(100% / 5)"
            class="w-100 d-flex justify-content-start border-bottom"
            v-for="intervention in interventions"
            :key="intervention.id"
          >
            <div class="h-100 py-1 mr-2 pl-2">
              <img
                :src="intervention.violation.image"
                class="h-100 w-auto"
                alt=""
              />
            </div>
            <div
              class="h-100 d-flex flex-column justify-content-start text-truncate"
            >
              <p class="m-0 font-smaller mt-1">
                {{ intervention.violation.name }}
              </p>
              <div class="mb-1" style="color: #bfc0bf">
                <p class="m-0 font-italic" style="font-size: 12px">
                  {{ intervention.user.username }}
                </p>
                <p
                  class="m-0 font-italic"
                  style="font-size: 12px; margin-top: -5px !important"
                >
                  {{ intervention.created_at }}
                </p>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api.js";
import DATE from "@/date.js"

export default {
  data() {
    return {
      isLoading: true,
      interventions: null,
      cameras: []
    };
  },
  methods: {
    hide() {
      this.$emit("hideIntervene");
    },
    setDate(date) {
      return DATE.full(date);
    },
    async getCameras() {
      let slug = this.$route.params.slug
      console.log(slug)
      const pres = await API.get(`projects?slug=${slug}`);
      let project = pres.data[0]
      
      await API.get(`cameras?project=${project.id}&_sort=name:ASC`).then(res => {
        console.log(res.data);
        res.data.forEach(element => {
          this.cameras.push({
            id: element.id,
            camName: element.name,
            interventions: this.interventions.filter(
              i => i.work.id == element.id
            )
          });
        });
        console.log(this.cameras);
        this.isLoading = false;
      });
    }
  },
  async mounted() {
    this.interventions = (
      await API.get(`interventionsByProject/${this.$route.params.slug}?_sort=created_at:DESC`)
    ).data;
    this.getCameras();
    console.log(this.interventions);
  }
};
</script>