<template>
  <div class="w-100" v-if="selectedSubwork">
    <div class="w-100" v-if="getDetections().length">
      <div class="w-100 px-3 py-2">
        <p class="m-0 font-smaller">
          <font-awesome-icon :icon="['far', 'eye']" class="mr-2" />DETECTIONS
        </p>
      </div>
      <div
        class="w-100 border bg-white d-flex align-items-center py-3 w-100 p-3 flex-wrap"
      >
        <!-- <p class="m-0 ml-3 font-smaller"> -->
          <!-- {{ getDetections() }} -->
          <span v-for="det in getDetections()" :key="det.name" class="badge badge-pill badge-dark m-1 px-3">{{
                det.name.replace(/_/g, ' ').replace('detection', '').replace('vest', 'coverall').toUpperCase()
            }}</span>
        <!-- </p> -->
      </div>
    </div>
    <div class="w-100">
      <div class="w-100 px-3 py-2 mt-3">
        <p class="m-0 font-smaller">
          <font-awesome-icon
            :icon="['fas', 'user-friends']"
            class="mr-2"
          />CONTRACTOR
        </p>
      </div>
      <div
        class="w-100 border bg-white d-flex align-items-center justify-content-start details-content py-3"
        v-if="selectedSubwork.contractor"
      >
        <img
          class="h-100 ml-3"
          :src="`https://prsb-api.inference.asia${selectedSubwork.contractor.logo.url}`"
          alt=""
        />
        <p class="m-0 ml-3 font-smaller">
          {{ selectedSubwork.contractor.name }}
        </p>
      </div>
      <div v-else class="w-100 border bg-white d-flex align-items-center justify-content-start details-content py-3">
        <p class="m-0 ml-3 font-smaller">
          Not set
        </p>
      </div>
    </div>
    <div class="w-100">
      <div class="w-100 px-3 py-2 mt-3">
        <p class="m-0 font-smaller">
          <font-awesome-icon
            :icon="['fas', 'briefcase']"
            class="mr-2"
          />PROJECT
        </p>
      </div>
      <div
        class="w-100 border bg-white d-flex align-items-center details-content py-3"
      >
        <p class="m-0 ml-3 font-smaller">{{ work.project.name}}</p>
      </div>
    </div>
    <div class="w-100">
      <div class="w-100 px-3 py-2 mt-3">
        <p class="m-0 font-smaller">
          <font-awesome-icon
            :icon="['fas', 'briefcase']"
            class="mr-2"
          />PROJECT PHASE
        </p>
      </div>
      <div
        class="w-100 border bg-white d-flex align-items-center details-content py-3"
        v-if="selectedSubwork.phase"
      >
        <p class="m-0 ml-3 font-smaller">{{ setPhaseText(selectedSubwork.phase) }}</p>
      </div>
      <div v-else class="w-100 border bg-white d-flex align-items-center justify-content-start details-content py-3">
        <p class="m-0 ml-3 font-smaller">
          Not set
        </p>
      </div>
    </div>
    <div class="w-100">
      <div class="w-100 px-3 py-2 mt-3">
        <p class="m-0 font-smaller">
          <font-awesome-icon
            :icon="['fas', 'globe-asia']"
            class="mr-2"
          />AREA
        </p>
      </div>
      <div
        class="w-100 border bg-white d-flex align-items-center details-content py-3"
      >
        <p class="m-0 ml-3 font-smaller">
          {{ selectedSubwork.area ? selectedSubwork.area : 'Not Set' }}
        </p>
      </div>
    </div>
    <!-- <div class="w-100">
      <div class="w-100 px-3 py-2 mt-3">
        <p class="m-0 font-smaller">
          <font-awesome-icon
            :icon="['fas', 'globe-asia']"
            class="mr-2"
          />AREA
        </p>
      </div>
      <div
        class="w-100 border bg-white d-flex align-items-center details-content py-3"
      >
        <p class="m-0 ml-3 font-smaller">
          {{ selectedSubwork.area ? selectedSubwork.area : 'Not Set' }}
        </p>
      </div>
    </div> -->
    
    <div class="w-100">
      <div class="w-100 px-3 py-2 mt-3">
        <p class="m-0 font-smaller">
          <font-awesome-icon :icon="['fas', 'hard-hat']" class="mr-2" />WORK
          TYPE
        </p>
      </div>
      <div
        class="w-100 border bg-white d-flex align-items-center details-content py-3"
      >
        <p class="m-0 ml-3 font-smaller">
          {{ selectedSubwork.hotwork ? "HOT WORK" : "COLD WORK" }}
        </p>
      </div>
    </div>
    <div class="w-100">
      <div class="w-100 px-3 py-2 mt-3">
        <p class="m-0 font-smaller">
          <font-awesome-icon :icon="['far', 'file-alt']" class="mr-2" />PTW
          NO.
        </p>
      </div>
      <div
        class="w-100 border bg-white d-flex align-items-center details-content py-3"
      >
        <p class="m-0 ml-3 font-smaller">
          {{ selectedSubwork.ptwNumber ? selectedSubwork.ptwNumber : 'Not Set' }}
        </p>
      </div>
    </div>
    <!-- <div class="w-100">
      <div class="w-100 px-3 py-2 mt-3">
        <p class="m-0 font-smaller">
          <font-awesome-icon :icon="['far', 'file-image']" class="mr-2" />PTW
          SNAPSHOT
        </p>
      </div>
      <div
        class="w-100 border bg-white d-flex align-items-center details-content py-3"
      >
        <p class="m-0 ml-3 font-smaller">
          N/A          
        </p>
      </div>
    </div> -->
    <div class="w-100 mb-3">
      <div class="w-100 px-3 py-2 mt-3">
        <p class="m-0 font-smaller">
          <font-awesome-icon :icon="['far', 'id-card']" class="mr-2" />HSE
          PERSONNEL
        </p>
      </div>
      <div class="w-100 border bg-white py-3">
        <p class="m-0 ml-3 font-smaller font-weight-bolder">
          Approving Authority
        </p>
        <p class="m-0 ml-3 font-smaller">
          {{ selectedSubwork.aaName ? selectedSubwork.aaName : 'Not Set' }}
        </p>
        <p class="m-0 ml-3 font-smaller" v-if="selectedSubwork.aaPhone">
          {{ selectedSubwork.aaPhone ? selectedSubwork.aaPhone : 'Not Set' }}
        </p>
      </div>
      <div class="w-100 border bg-white py-3">
        <p class="m-0 ml-3 font-smaller font-weight-bolder">
          Receiving Authority
        </p>
        <p class="m-0 ml-3 font-smaller">
          {{ selectedSubwork.raName ? selectedSubwork.raName : 'Not Set' }}
        </p>
        <p class="m-0 ml-3 font-smaller" v-if="selectedSubwork.raPhone">
          {{ selectedSubwork.raPhone }}
        </p>
      </div>
      <div class="w-100 border bg-white py-3">
        <p class="m-0 ml-3 font-smaller font-weight-bolder">
          Work Leader
        </p>
        <p class="m-0 ml-3 font-smaller">
          {{ selectedSubwork.wlName ? selectedSubwork.wlName : 'Not Set' }}
        </p>
        <p class="m-0 ml-3 font-smaller" v-if="selectedSubwork.wlPhone">
          {{ selectedSubwork.wlPhone }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["subwork", "work"],
  data() {
    return {
      selectedSubwork: null,
    };
  },  
  watch: {
	subwork() {
		this.selectedSubwork = this.subwork;	
		console.log(this.selectedSubwork);
	}  
  },
  methods: {
    setPhaseText(phase){
      let phases = [
          {
            name: "Site Prep",
            id: "prep"
          },
          {
            name: "Execution",
            id: "execution"
          },
          {
            name: "Commissioning",
            id: "commissioning"
          },
          {
            name: "Provisional",
            id: "provisional"
          }
        ]

        return phases.find(p => p.id == phase) ? phases.find(p => p.id == phase).name : 'Not Set'
    },
    getDetections() {
		if (this.selectedSubwork === null)
			return []
			
      var detections = [];
      for (const [key, value] of Object.entries(this.selectedSubwork)) {
        if (key.includes("_detection"))
          detections.push({ name: key, value: value });
      }
      return detections.filter(d => d.value)
    },
  },
  mounted() {
    this.selectedSubwork = this.subwork;
    console.log(this.selectedSubwork)
  },
};
</script>