import moment from 'moment'

export default {
    simple: (datetime) => {
        return moment(datetime).utc().format('D MMM')
    },

    full(datetime) {
        return moment(datetime).format('DD/MM/YYYY hh:mma')
    },
	
	date(datetime) {
		return moment(datetime).format('DD/MM/YYYY')
	},
    unixToFull (datetime){
        return moment.unix(datetime).format('YYYY/MM/DD HH:mm:ss')
    },
    current: () => {
        return moment().format('YYYY/MM/DD HH:mm:ss')
    }
}