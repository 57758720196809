<template>
  <div class="page-default padding-subnav">
    <div class="container-fluid h-100 p-0">
      <div class="row h-100 m-0 position-relative camera-layout">
        <div class="col order-2 bg-black p-0 m-0 right-container">
          <div
            class="
              h-100
              w-100
              d-flex
              justify-content-center
              align-items-center
              position-relative
            "
          >
            <div
              class="
                h-100
                w-100
                d-flex
                justify-content-center
                align-items-center
              "
            >
              <img
                id="imageElement"
                :src="camera.broadcasts[0].stream_url + '?' + cacheKey"
                alt=""
                v-if="camera && !isLive && !selectedRecording"
              />
              <video
                id="videoElement"
                class="w-100"
                muted
                :class="{ 'd-none': !isLive && !selectedRecording }"
              >
                <source id="mp4" type="video/mp4" />
              </video>
              <img
                src="@/assets/images/record.png"
                alt=""
                v-if="selectedRecording"
                @click="selectedRecording = null"
                style="
                  width: 301.5px;
                  cursor: pointer;
                  position: absolute;
                  top: 10px;
                  left: 10px;
                "
              />
              <div
                class="d-flex align-items-center"
                @click="customIntervenClick"
                v-if="!selectedRecording"
                style="
                  height: 25px;
                  cursor: pointer;
                  background: white;
                  position: absolute;
                  top: 10px;
                  left: 10px;
                  border-radius: 100px;
                "
              >
                <div
                  class="h-100 w-auto pr-2 pl-3 d-flex align-items-center"
                  style="
                    background-color: #f1f2f1;
                    padding: 0.35rem 1rem;
                    border-radius: 100px 0px 0px 100px;
                  "
                >
                  <img
                    class="h-100"
                    src="@/assets/images/microphone.png"
                    alt=""
                  />
                </div>
                <div
                  class="
                    h-100
                    d-flex
                    align-items-center
                    justify-content-between
                    py-1
                    pl-2
                    pr-3
                  "
                >
                  <p class="m-0 text-secondary" style="font-size: 9px">
                    {{ recordingText }}
                  </p>
                  <font-awesome-icon
                    :icon="['fa', 'record-vinyl']"
                    v-if="isRecordIntervene"
                    class="ml-2 font-small font-weight-light text-danger"
                  />
                </div>
              </div>

              <div
                class="d-flex align-items-center"
                v-if="!selectedRecording"
                @click="forceStop"
                style="
                  height: 25px;
                  cursor: pointer;
                  background: red;
                  position: absolute;
                  top: 10px;
                  right: 10px;
                  border-radius: 100px;
                "
              >
                <div
                  class="
                    h-100
                    d-flex
                    align-items-center
                    justify-content-between
                    py-1
                    px-3
                  "
                >
                  <font-awesome-icon
                    :icon="['fa', 'exclamation-circle']"
                    class="mr-2 font-small font-weight-light text-white"
                  />
                  <p
                    class="m-0 text-white"
                    style="font-size: 9px; font-weight: bold"
                  >
                    Stop
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col order-1 p-0 m-0 left-container">
          <div class="header-container">
            <!----------------------- Work Date selection ------------------------------------>
            <div class="bg-darkgreen">
              <div class="w-100 p-3 text-white">
                <div class="d-flex justify-content-between align-items-center">
                  <div
                    class="
                      d-flex
                      justify-content-start
                      align-items-center
                      mb-3
                      goback
                    "
                  >
                    <font-awesome-icon
                      :icon="['fa', 'arrow-left']"
                      class="mr-2 font-large font-weight-bold"
                      @click="goTo('Home')"
                    />
                    <p
                      v-if="camera"
                      class="
                        font-weight-normal
                        m-0
                        body
                        cursor-pointer
                        left-title
                      "
                      @click="goTo('Home')"
                    >
                      {{ camera.name }}
                    </p>
                  </div>
                  <font-awesome-icon
                    :icon="['fas', 'edit']"
                    class="mb-3 font-large cursor-pointer"
                    @click="isShowEditModal = true"
                  />
                </div>

                <p class="font-smaller m-0 body mb-2">
                  <font-awesome-icon
                    :icon="['far', 'calendar-alt']"
                    class="mr-2"
                  />WORK DATE
                </p>
                <div class="w-100" v-if="subWorks">
                  <select
                    id="selectRoad"
                    class="custom-select font-smaller"
                    v-model="models.subwork"
                    @change="subWorkChange()"
                  >
                    <option :value="null" disabled>Select a work date</option>
                    <option
                      v-for="subwork in subWorks"
                      :key="subwork.id"
                      :value="subwork"
                    >
                      {{ setDate(subwork.created_at) }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <!----------------------- TABS ------------------------------------>
            <div
              class="
                w-100
                d-flex
                justify-content-around
                align-items-center
                bg-white
              "
              v-if="selectedSubwork"
            >
              <div
                class="w-100 tabs px-2"
                :class="{ selected: selectedTabs == 0 }"
                @click="selectTab(0)"
              >
                <span class="m-0 font-smaller">
                  <font-awesome-icon
                    :icon="['fas', 'list-alt']"
                    class="mr-2"
                  />DETAILS
                </span>
              </div>
              <div
                class="w-100 tabs px-2"
                :class="{ selected: selectedTabs == 1 }"
                @click="selectTab(1)"
              >
                <span class="m-0 font-smaller">
                  <font-awesome-icon
                    :icon="['fas', 'exclamation-triangle']"
                    class="mr-2"
                  />VIOLATIONS
                </span>
              </div>
              <div
                class="w-100 tabs px-2"
                :class="{ selected: selectedTabs == 2 }"
                @click="selectTab(2)"
              >
                <span class="m-0 font-smaller">
                  <font-awesome-icon
                    :icon="['far', 'file-video']"
                    class="mr-2"
                  />RECORDINGS
                </span>
              </div>
            </div>
            <!----------------------- TABS END ------------------------------------>
          </div>
          <!----------------------- Details ------------------------------------>
          <div class="content" v-if="camera">
            <div class="w-100" v-if="selectedTabs == 0 && selectedSubwork">
              <Details :subwork="selectedSubwork" :work="camera" />
            </div>
            <div class="w-100 mt-3" v-if="selectedTabs == 1 && selectedSubwork">
              <Violations
                :subwork="selectedSubwork"
                :camera="camera"
                @violationSelected="selectiVolation"
                @intervene="intervene"
              />
            </div>

            <div class="w-100" v-if="selectedTabs == 2 && selectedSubwork">
              <Recordings
                :subwork="selectedSubwork.id"
                :selectedRecording="selectedRecording"
                @recordSelected="recordSelected"
              />
            </div>
          </div>
          <!----------------------- Details End------------------------------------>
        </div>
      </div>
    </div>
    <media-preview
      v-if="selectedViolation"
      :url="setImageUrl(selectedViolation.image)"
      @close="selectedViolation = null"
      :title="selectedViolation.name"
    />
    <work-form-modal
      v-if="isShowEditModal"
      @close="closeModal"
      :camera="camera"
      :subwork="selectedSubwork"
      :options="options"
    />
  </div>
</template>

<script>
import API from "@/api.js";
import DATE from "@/date.js";
import Details from "../components/Details.vue";
import Violations from "../components/Violations.vue";
import MediaPreview from "@/components/MediaPreview";
import Swal from "sweetalert2";
import Recordings from "../components/Recordings.vue";
// import Pusher from "pusher-js";
import WorkFormModal from "@/components/modals/WorkForm.vue";

import flvjs from "flvjs";
import axios from "axios";

export default {
  components: {
    Details,
    MediaPreview,
    Recordings,
    Violations,
    WorkFormModal
  },
  data() {
    return {
      pusher: null,
      camera: null,
      isShowEditModal: false,
      models: {
        subwork: null
      },
      options: {
        contractor: null,
        phase: [
          {
            name: "Site Prep",
            id: "prep"
          },
          {
            name: "Execution",
            id: "execution"
          },
          {
            name: "Commissioning",
            id: "commissioning"
          },
          {
            name: "Provisional",
            id: "provisional"
          }
        ]
      },
      isLive: false,
      subWorks: null,
      selectedSubwork: null,
      selectedTabs: 0,
      selectedViolation: null,
      timer: null,
      cacheKey: +new Date(),
      flvPlayer: null,
      selectedRecording: null,
      audioContext: null,
      rec: null,
      input: null,
      isRecordIntervene: false,
      mediaStream: null
      // AudioContext: window.AudioContext || window.webkitAudioContex
    };
  },
  computed: {
    recordingText() {
      return this.isRecordIntervene
        ? "Recording, Click here to stop."
        : "Click here to talk via the camera speaker.";
    },
    sortedViolations() {
      if (this.selectedSubwork == null) return [];

      return [...this.selectedSubwork.violations].sort(
        (a, b) => b.timestamp - a.timestamp
      );
    }
  },
  watch: {
    subWorks() {
      if (this.subWorks.length == 0) return;

      this.models.subwork = this.subWorks[0];
      this.getSubwork();
    },
    camera() {
      if (this.camera) {
        this.loadVideo();
      }
    },
    selectedRecording() {
      this.loadVideo();
    }
  },
  methods: {
    async forceStop() {
      Swal.fire({
        title: "Confirmation",
        text: "Are you sure you want to force-stop this stream?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, stop it"
      }).then(result => {
        if (result.isConfirmed) {
          axios.get(
            `https://untitled-fy4y0ek4x93g.runkit.sh/${this.camera.streamName}`
          );
          Swal.fire("Notification", "Stream stop successfully.", "success");
        }
      });
      console.log(this.camera);
    },
    setImageUrl(url) {
      return url.includes("https://prsb-socket.inference.asia")
        ? url.replace(
            "https://prsb-socket.inference.asia",
            "https://aisc-media.sgp1.digitaloceanspaces.com"
          )
        : url.replace(
            "https://aisc-media.sgp1.cdn.digitaloceanspaces.com",
            "https://aisc-media.sgp1.digitaloceanspaces.com"
          );
    },
    selectiVolation(violation) {
      this.selectedViolation = violation;
    },
    closeModal(e) {
      this.isShowEditModal = false;
      if (e) {
        this.selectedSubwork = e;
      }
    },
    recordSelected(e) {
      this.selectedRecording = e;
    },
    setStreamName(url) {
      var splits = url.replace("https://", "").split(".");

      return splits[0];
    },
    loadVideo() {
      if (this.camera) {
        if (flvjs.isSupported()) {
          var videoElement = document.getElementById("videoElement");
          if (typeof this.flvPlayer !== "undefined") {
            if (this.flvPlayer != null) {
              this.flvPlayer.unload();
              this.flvPlayer.detachMediaElement();
              this.flvPlayer.destroy();
              this.flvPlayer = null;
            }
          }
          var mp4 = document.getElementById("mp4");
          if (this.selectedRecording) {
            videoElement.controls = true;
            mp4.src = this.selectedRecording.url;
            videoElement.load();
            videoElement.play();
          } else {
            videoElement.controls = false;
            mp4.src = null;
            this.flvPlayer = flvjs.createPlayer({
              type: this.selectedRecording ? "video/mp4" : "flv",
              url:
                "https://playback.inference.asia/live/" +
                this.setStreamName(this.camera.broadcasts[0].stream_url) +
                ".flv",
              hasAudio: false,
              enableStashBuffer: false
            });
            this.flvPlayer.attachMediaElement(videoElement);
            this.flvPlayer.load();
            this.flvPlayer.play();
          }
        }
      }
    },
    setDate(date) {
      return DATE.date(date);
    },
    subWorkChange() {
      this.selectTab(0);
      this.getSubwork();
    },
    uploadIntervene(blob) {
      const streamUrl = this.camera.broadcasts[0].stream_url;
      const user = JSON.parse(localStorage.getItem("user"));
      const number = streamUrl.match(/\d+/g).join("");

      const formData = new FormData();
      formData.append("audio", blob);
      formData.append("streamKey", `stream${number}`);
      formData.append("userId", user.id);
      API.postForm("interventions/talk", formData)
        .then(() => {
          Swal.fire("Notification", "Workers alerted successfully.", "success");
        })
        .catch(e => {
          console.log(e);
        });
    },
    customIntervenClick() {
      this.isRecordIntervene
        ? this.stopRecordingIntervene(this.mediaStream)
        : this.startRecordingIntervene();
    },
    stopRecordingIntervene(stream) {
      if (this.rec && stream) {
        this.rec.stop();
        stream.getAudioTracks()[0].stop();
      }
    },
    startRecordingIntervene() {
      console.log("custom intervene");
      var constraints = { audio: true, video: false };
      // var AudioContext = window.AudioContext || window.webkitAudioContext;
      let self = this;
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function(stream) {
          self.isRecordIntervene = true;
          self.mediaStream = stream;
          self.rec = new MediaRecorder(stream);
          let chunks = [];

          self.rec.ondataavailable = e => {
            console.log(e);
            chunks.push(e.data);
          };

          self.rec.onstop = e => {
            console.log(e);
            self.isRecordIntervene = false;
            const blob = new Blob(chunks, { type: "audio/ogg; codecs=opus" });
            chunks = [];
            self.uploadIntervene(blob);
          };
          self.rec.start();
          // I do not want a timeout to stop the recording but clicking the stop button to do so
          setTimeout(() => {
            if (self.isRecordIntervene) {
              self.stopRecordingIntervene(self.mediaStream);
            }
          }, 10000);
        })
        .catch(function(err) {
          console.log(err);
          Swal.fire(
            "Notification",
            "This features require microphone permission.",
            "warning"
          );
          //enable the record button if getUserMedia() fails
        });
    },
    intervene(violation) {
      const streamUrl = this.camera.broadcasts[0].stream_url;
      const user = JSON.parse(localStorage.getItem("user"));
      const number = streamUrl.match(/\d+/g).join("");
      Swal.fire({
        title: "Confirmation",
        text: "Are you sure you want to notify the workers via speaker?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, notify them"
      }).then(result => {
        if (result.isConfirmed) {
          API.post("interventions/sound", {
            streamKey: `stream${number}`,
            violation: violation.id,
            userId: user.id
          }).then(() => {
            Swal.fire(
              "Notification",
              "Workers alerted successfully.",
              "success"
            );
          });
        }
      });
    },
    getCamera() {
      API.get(`cameras/${this.$route.params.id}`)
        .then(res => {
          this.camera = res.data;
        })
        .catch(err => {
          console.log(err);
          alert(`${err}`);
        });
    },
    async toggleViolation(violation) {
      const index = this.selectedSubwork.violations.indexOf(violation);
      this.selectedSubwork.violations[index].isCounted = !violation.isCounted;
      await API.put(`violations/${violation.id}`, {
        isCounted: this.selectedSubwork.violations[index].isCounted
      });
    },
    getSubworks() {
      API.get(
        `subworks?work=${this.$route.params.id}&_sort=created_at:DESC&_limit=-1`
      )
        .then(res => {
          console.log("getSubwork");
          this.subWorks = res.data;
        })
        .catch(err => {
          console.log(err);
          alert(`${err}`);
        });
    },
    getSubwork() {
      API.get(`subworks/${this.models.subwork.id}`)
        .then(res => {
          this.selectedSubwork = res.data;
        })
        .catch(err => {
          console.log(err);
          alert(`${err}`);
        });
    },
    getContractor() {
      API.get(`contractors`)
        .then(res => {
          this.options["contractor"] = res.data.map(contractor => ({
            ...contractor,
            Contractor: contractor.name
          }));
        })
        .catch(err => {
          console.log(err);
          alert(`${err}`);
        });
    },
    selectTab(tab) {
      this.selectedTabs = tab;
    },
    goTo(name) {
      if (this.$route.name != name) {
        this.$router.push({
          name: name
        });
      }
    }
  },
  mounted() {
    this.getCamera();
    this.getSubworks();
    this.getContractor();

    flvjs.LoggingControl.addLogListener((type, str) => {
      console.log(str);
      if (str.includes("onSourceEnded")) {
        this.isLive = false;
        this.loadVideo();
      }

      if (str.includes("Received Initialization")) {
        if (typeof this.flvPlayer !== "undefined" && this.flvPlayer != null) {
          this.isLive = true;
          this.flvPlayer.play();
        }
      }
    });

    // this.pusher = new Pusher("546809d823ede5cbc2d7", {
    //   cluster: "ap1",
    //   forceTLS: true
    // });

    // this.pusher.connection.bind("connected", () => {
    //   console.log("Pusher connected");
    // });

    // const channel = this.pusher.subscribe("aisc");

    // channel.bind("violation", (data) => {
    //   console.log(data)
    //   if (this.selectedSubwork == null) return;

    //   this.getSubwork();
    // });
  },

  beforeDestroy() {
    // if (this.pusher) this.pusher.disconnect();
  }
};
</script>
