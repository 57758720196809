<template>
  <modal id="workFormModal" title="Edit Work" @close="close">
    <div class="form">
      <div class="row pb-4">
        <template v-for="input in inputs">
          <component
            :is="`${input.type}-input`"
            :key="input.id"
            :input="input"
            :options="options && options[input.id]"
            class="col-12"
            :class="`${
              input.col
                ? input.col == 0
                  ? 'col-sm'
                  : 'col-sm-'.concat(input.col)
                : 'col-sm-12'
            }`"
            @change="inputChanged"
          />
        </template>
      </div>
      <div
        class="
          pt-3
          border-top
          d-flex
          justify-content-between
          align-items-center
        "
      >
        <button
          class="btn btn-light px-4"
          :disabled="isProcessing"
          @click="close"
        >
          Cancel
        </button>
        <button
          class="btn btn-success px-4"
          :disabled="isProcessing"
          @click="save()"
        >
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
            v-if="isProcessing"
          ></span>
          <span v-if="!isProcessing">Save</span>
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import API from "@/api";
import Modal from "@/components/Modal";
import TextInput from "@/components/inputs/Text";
import SelectInput from "@/components/inputs/Select";
import SwitchInput from "@/components/inputs/Switch";

export default {
  props: ["camera", "options", "subwork"],
  components: {
    Modal,
    TextInput,
    SelectInput,
    SwitchInput
  },
  data() {
    return {
      isProcessing: false,
      inputs: [
        {
          type: "select",
          label: "Contractor",
          id: "contractor",
          model: "",
          disabled: false
        },
        {
          type: "select",
          label: "Project Phase",
          id: "phase",
          model: "",
          disabled: false
        },
        {
          type: "text",
          label: "Area",
          id: "area",
          model: "",
          disabled: false
        },
        {
          type: "switch",
          label: "Hotwork",
          id: "hotwork",
          model: "",
          disabled: false
        },
        {
          type: "text",
          label: "PTW Number",
          id: "ptwNumber",
          model: "",
          disabled: false
        },
        {
          type: "text",
          label: "Approving Authority Name",
          id: "aaName",
          model: "",
          col: 6,
          disabled: false
        },
        {
          type: "text",
          label: "Approving Authority Number",
          id: "aaPhone",
          model: "",
          col: 6,
          disabled: false
        },
        {
          type: "text",
          label: "Receiving Authority Name",
          id: "raName",
          model: "",
          col: 6,
          disabled: false
        },
        {
          type: "text",
          label: "Receiving Authority Number",
          id: "raPhone",
          model: "",
          col: 6,
          disabled: false
        },
        {
          type: "text",
          label: "Work Leader Name",
          id: "wlName",
          model: "",
          col: 6,
          disabled: false
        },
        {
          type: "text",
          label: "Work Leader Number",
          id: "wlPhone",
          model: "",
          col: 6,
          disabled: false
        }
      ]
    };
  },
  watch: {
    camera: {
      handler() {
        if (this.camera != null) {
          this.inputs = this.inputs.map(input => {
            console.log(input.id);
            input.model =
              this.subwork &&
              this.subwork[input.id] &&
              typeof this.subwork[input.id] == "object"
                ? this.subwork[input.id].id
                : this.subwork[input.id];
            console.log('input.model', input.model);
            return input;
          });
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    inputChanged(e) {
      let input = this.inputs.find(i => i.id == e.id);

      input.model = e.model;
      console.log(input)
    },
    close() {
      if (!this.isProcessing) {
        this.$emit("close");
      }
    },
    async save() {
      this.isProcessing = true;

      let url = "works";
      // let method = "put";

      let body = {};

      url += `/${this.camera.id}`;

      this.inputs.forEach(input => {
        body[input.id] = input.model;
      });

      await API.put(url, body);
      API.put(`subworks/${this.subwork.id}`, body)
        .then(retVal => {
          this.isProcessing = false;
          console.log(JSON.stringify(retVal.data));
          this.$emit("close", retVal.data);
        })
        .catch(err => {
          console.error(err);
          this.isProcessing = false;
        });
    }
  },
  mounted() {
    console.log(this.camera);
  }
};
</script>